<template>
    <div class="commits">
      <div class="nav-topbar">
        <div class="mobiles-nav-list">
          <a class="nav-backs" @click="backs()"> 返回 </a>
        </div>
        <div class="nav-list">
          <a class="nav-backs" @click="backs()"> 返回 </a>
          <a class="nav-items">我的帖子 </a>
        </div>
      </div>
      <div class="centers-el">
        <div class="left">
          <el-table :data="List" style="width: 100%">
            <el-table-column prop="createtime" label="创建日期">
            </el-table-column>
            <el-table-column prop="title" label="标题">
            </el-table-column>
            <!--
          <el-table-column prop="maincontent" label="内容">
            <template slot-scope="scope">
              <div v-html="scope.row.maincontent"></div>
            </template>
          </el-table-column>-->
            <el-table-column fixed="right" label="操作" width="168px">
              <template slot-scope="scope">
                <el-button @click.native.prevent="CommitRow(scope.row)" type="text" size="small">
                  查看帖子
                </el-button>
                <el-button @click.native.prevent="DelRow(scope.row)" type="text" size="small">
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="page-el">
            <el-pagination background @current-change="handleCurrentChange" :current-page="currentPage"
              :page-size="pageSize" layout="total,prev,pager,next" :total="total">
            </el-pagination>
          </div>
        </div>
        <div class="right">
          <div class="hot_lists">
            <div class="hot_top_tites">很想很想你的热榜 · · · · · ·</div>
            <HotItem :hotList="hotList" :hotTotal="hotTotal" @handleHotCurrentChange="handleHotCurrentChange"></HotItem>
          </div>
        </div>
      </div>
  
      <!--
      <el-dialog title="评论列表" :visible.sync="dialogFormVisible" width="50%">
        <div class="dialog-body">
          <div class="commit-center">
            <div v-if="Lists.length == 0" style="text-align:center">暂无评论</div>
            <div class="commit-items" v-for="(vals, index) in Lists" :key="index">
              <el-row :gutter="20" class="el-row">
                <el-col :span="24">
                  <el-avatar size="large" :src="baseUrl + vals.user.avatar"></el-avatar>
                </el-col>
                <el-col :span="24"><span>{{ vals.user.username }}</span></el-col>
              </el-row>
              <div v-html="vals.maincontent"></div>
              <div v-html="vals.createtime"></div>
  
              <div style="margin-top:12px">
                <el-button type="warning" plain @click="process = vals" size="mini">回复</el-button>
              </div>
              <div v-show="process == vals">
                <vue-editor id="editor" class="editor" useCustomImageHandler @image-added="handleImageAdded"
                  v-model="content" :editor-toolbar="customToolbar"> </vue-editor>
                <el-button type="warning" plain @click="submit()" size="mini">提交</el-button>
                <el-button type="danger" @click="process = ''" plain size="mini">收回</el-button>
              </div>
              <div class="commit-items" v-for="(valss, indexs) in vals.pick.data" :key="indexs">
                <el-row :gutter="20" class="el-row" style="margin-top:12px">
                  <el-col :span="24">
                    <el-avatar size="large" :src="baseUrl + valss.user.avatar"></el-avatar>
                  </el-col>
                  <el-col :span="24"><span>{{ valss.user.username }}</span></el-col>
                </el-row>
                <el-row :gutter="20" class="el-row" v-if="valss.replyuser" style="margin-top:12px">
                  <el-col :span="24">
                    >>
                  </el-col>
                  <el-col :span="24">
                    <el-avatar size="large" :src="baseUrl + valss.replyuser.avatar"></el-avatar>
                  </el-col>
                  <el-col :span="24"><span>{{ valss.replyuser.username }}</span></el-col>
                </el-row>
                <div v-html="valss.maincontent"></div>
                <div v-html="valss.createtime"></div>
                <div style="margin-top:12px">
                  <el-button type="warning" plain @click="process = valss" size="mini">回复</el-button>
                </div>
                <div v-show="process == valss">
                  <vue-editor id="editor" class="editor" useCustomImageHandler @image-added="handleImageAdded"
                    v-model="contents" :editor-toolbar="customToolbar"> </vue-editor>
                  <el-button type="warning" plain @click="submits()" size="mini">提交</el-button>
                  <el-button type="danger" @click="process = ''" plain size="mini">收回</el-button>
                </div>
                <el-button type="primary" plain @click="getpicklist(vals, index)" size="mini"
                  style="margin-top:12px">更多</el-button>
              </div>
            </div>
          </div>
        </div>
        <div class="page-el">
          <el-pagination background @current-change="handleCurrentChanges" :current-page="currentPages"
            :page-size="pageSize" layout="total,prev,pager,next" :total="totals">
          </el-pagination>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button type="warning" @click="dialogFormVisible = false">关闭</el-button>
        </div>
      </el-dialog>-->
    </div>
  </template>
  <script>
  import { VueEditor } from "vue2-editor"
  import ApiCon from '../util/Api/index'
  import HotItem from '@/components/HotSticks';
  import axios from "axios"
  export default {
    name: 'Reply',
    data() {
      return {
        List: [],
        Lists: [],
        vals: '',
        currentPages: 1,
        ppage: 1,
        dialogFormVisibles: false,
        content: '',
        category_ids: '',
        contents: '',
        val: '',
        total: 0,
        totals: 0,
        hotTotal: 0,
        hotList: [],
        currentPage: 1,  // 当前页码
        currentPages: 1,  // 当前页码
        process: '',
        pageSize: 10,  // 每页显示的行数
        dialogFormVisible: false,
        customToolbar: [
          [{ 'header': [false, 1, 2, 3, 4, 5, 6] }], //标题
          ['bold', 'italic', 'underline'], //加粗\斜体\下划线
          ['italic'], //斜体----也可以拆开来写，用于换位置顺序
          [{ 'align': '' }, { 'align': 'center' }, { 'align': 'right' }], //对齐
          [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' }], //有序无序列表
          [{ 'background': [] }, { 'color': [] }], //背景色
          ['image', 'link'], //图片、链接
          ['strike'], //作废（文字中间一横）
          ['underline'],
          ['clean'], //清楚格式（所谓去掉背景色这些花里胡巧）
          ['code'], //代码
          ['code-block'] //代码块
          //['video'] 视频
        ],
      }
    },
    mounted() {
      this.ApiCon = new ApiCon(this.$store)
      this.ApiConUrl = this.ApiCon.getApiUrl()
    },
    created() {
      this.ApiCon = new ApiCon(this.$store)
      this.baseUrl = this.ApiCon.getApiUrl()
      this.init()
      let category_ids = localStorage.getItem("sticks_category_ids")
      if (category_ids) {
        this.category_ids = category_ids
      }else{
        this.category_ids='30'
      }
    },
    components: {
      VueEditor,
      HotItem
    },
    methods: {
      backs: function () {
        //this.$router.push("/index");
        history.go(-1)
      },
      handleHotCurrentChange(val) {
        this.hotList = [];
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        this.$api.mall
          .getHotSticksList({
            page: val,
            type: this.category_ids,
            limit: this.pageSize,
          })
          .then((ress) => {
            let res = ress.data;
            for (var i = 0; i < res.data.data.length; i++) {
              res.data.data[i].shows = true;
              res.data.data[i].tag = res.data.data[i].tag.split(",");
            }
            this.hotTotal = res.data.total;
            this.hotList = res.data.data;
          });
      },
      getHotLists() {
        this.hotList = [];
        this.$api.mall
          .getHotSticksList({
            page: 1,
            type: this.category_ids,
            limit: this.pageSize,
          })
          .then((ress) => {
            let res = ress.data;
            for (var i = 0; i < res.data.data.length; i++) {
              res.data.data[i].shows = true;
              res.data.data[i].tag = res.data.data[i].tag.split(",");
            }
            this.hotTotal = res.data.total;
            this.hotList = res.data.data;
          });
      },
      submit() {
        let self = this
        if (!window.localStorage.getItem("token")) {
          self.$message.error("请先登录或者注册")
          return
        }
        if (!self.content) {
          self.$message.error("请您填入内容")
          return
        }
        this.$api.mall.stickCommitadd({
          maincontent: self.content,
          isreply: 2,
          ids: self.process.ids,
          pick: self.process.id,
          pickuser: self.process.user.id,
          reply: '',
          replyuser: ''
        }).then((res) => {
          console.log(res)
          if (res.code == 1) {
            self.$message.success("成功")
          } else {
            self.$message.error("失败")
          }
        });
      },
      submits() {
        let self = this
        if (!window.localStorage.getItem("token")) {
          self.$message.error("请先登录或者注册")
          return
        }
        if (!self.contents) {
          self.$message.error("请您填入内容")
          return
        }
        this.$api.mall.stickCommitadd({
          maincontent: self.contents,
          isreply: 2,
          ids: self.process.ids,
          pick: self.process.pick,
          pickuser: self.process.pickuser.id,
          reply: self.process.id,
          replyuser: self.process.user.id
        }).then((res) => {
          console.log(res)
          if (res.code == 1) {
            self.$message.success("成功")
          } else {
            self.$message.error("失败")
          }
        });
      },
      getpicklist(val, index) {
        let self = this
        console.log(index)
        self.ppage = self.ppage + 1
        this.$api.mall.getPickListstick({
          pick: val.pick.data[0].pick,
          page: self.ppage,
          limit: 14,
        }).then((ress) => {
          let res = ress.data
          console.log(res)
          for (var i = 0; i < res.data.data.length; i++) {
            this.List[index].pick.push(res.data.data[i])
          }
        });
      },
      CommitRow(val) {
        let self = this;
        self.$router.push("/Receipt/" + val.sticks_id);
        //this.dialogFormVisible = true
        //stickCommitList
        /**
        this.Lists = []
        this.$api.mall.stickCommitList({
          page: 1,
          limit: 14,
          ids: val.id,
        }).then((ress) => {
          let res = ress.data
          this.Lists = res.data.data
          this.totals = res.data.current_page
        });**/
      },
      DelRow(val) {
        let self = this
        this.$confirm('此操作将删除该收藏, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$api.mall.delcollects({
            id: val.id,
            sticks_id: val.sticks_id
          }).then((res) => {
            if (res.code == 1) {
            let collects=res.data
            localStorage.setItem('collects_ids',collects)
            self.$message.success("取消成功");
            self.init()
            } else {
            self.$message.error("取消失败");
            }
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      handleRemove(file, fileList) {
        console.log(file, fileList);
      },
      handlePreview(file) {
        console.log(file);
      },
      async handleImageAdded(file, Editor, cursorLocation, resetUploader) {
        let self = this
        if (!window.localStorage.getItem("token")) {
          self.$message.error("请先登录或者注册")
          return
        }
        // An example of using FormData
        // NOTE: Your key could be different such as:
        // formData.append('file', file)
        var formData = new FormData();
        formData.append("file", file);
        axios({
          url: "https://sosunlive.com/api/common/upload",
          method: "POST",
          data: formData
        })
          .then(result => {
            // console.log(result, "009966") self.baseUrl +
            let url = result.data.data.url; // Get url from response
            Editor.insertEmbed(cursorLocation, "image", url);
            resetUploader();
          })
          .catch(err => {
            console.log(err);
          });
      },
      updateData(e) {
        this.content = e;
      },
      handleCurrentChange(val) {
        this.currentPage = val
        //console.log(this.ApiCon.gets('pages'))
        //this.$router.push("/cart");
        document.documentElement.scrollTop = 0
        this.list = []
        this.$api.mall.getcollects({
          page: this.currentPage,
          limit: this.pageSize
        }).then((res) => {
          for (var i = 0; i < res.data.data.length; i++) {
            res.data.data[i].shows = true
          }
          this.total = res.data.current_page
          this.List = res.data.data
        });
      },
      handleCurrentChanges(val) {
        this.currentPages = val
        //console.log(this.ApiCon.gets('pages'))
        //this.$router.push("/cart");
        //document.documentElement.scrollTop = 0
        this.lists = []
        this.$api.mall.stickCommitList({
          ids: this.val.id,
          page: this.currentPages,
          limit: this.pageSize
        }).then((res) => {
          for (var i = 0; i < res.data.data.length; i++) {
            res.data.data[i].shows = true
          }
          this.totals = res.data.current_page
          this.Lists = res.data.data
        });
      },
      init() {
        //speaUserList
        this.List = []
        this.$api.mall.getcollects({
          page: 1,
          limit: 14,
        }).then((ress) => {
          let res = ress.data
          //console.log(res,"99999999")
          this.List = res.data.data
          this.total = res.data.current_page
          this.getHotLists()
        }).catch(err => {
          // 报错
          //  console.log(err,"009999")
        })
      }
    }
  }
  </script>
  <style lang="scss" scoped>
  @media (max-width: 8000px) and (min-width: 1024px) {
    .commits {
      width: 100%;
      overflow: hidden;
      margin: 0px auto;
      padding: 0px;
  
      .centers-el {
        width: 980px;
        overflow: hidden;
        margin: 48px auto auto auto;
  
        .left {
          width: 680px;
          overflow: hidden;
          float: left;
          box-sizing: border-box;
        }
  
        .right {
          width: 280px;
          overflow: hidden;
          background: #fff;
          -webkit-box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
          box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          float: right;
  
          .hot_lists {
            width: 100%;
            margin: 10px auto;
            border-radius: 2px;
            -webkit-box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
            box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
            background: #fff;
            font-size: $fontJ;
            box-sizing: border-box;
            padding: 10px;
            overflow: hidden;
  
            .hot_top_tites {
              width: 100%;
              overflow: hidden;
              margin: 0px auto;
              color: rgb(217, 83, 80);
              font-size: 14px;
            }
          }
        }
      }
  
      .page-el {
        width: 980px;
        overflow: hidden;
        margin: 10px auto auto auto;
      }
  
      .nav-topbar {
        width: 100%;
        overflow: hidden;
        background: #ffe url('/imgs/fade.png') top center repeat-x;
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 10;
  
        .mobiles-nav-list {
          display: none;
        }
  
        .nav-list {
          width: 980px;
          overflow: hidden;
          margin: 0px auto;
  
          .searchTexts {
            width: 50%;
            float: right;
            overflow: hidden;
            margin: 6px auto;
          }
  
          .nav-backs {
            text-decoration: none;
            padding: 1px;
            display: block;
            margin-left: 6px;
            font-weight: 400;
            overflow: hidden;
            color: maroon;
            line-height: 42px;
            text-align: center;
            float: left;
            cursor: pointer;
          }
  
          .nav-items {
            text-decoration: none;
            padding: 1px;
            display: block;
            margin-left: 6px;
            font-weight: 400;
            overflow: hidden;
            color: maroon;
            line-height: 42px;
            text-align: center;
            float: left;
          }
  
          .nav-backs:hover {
            color: red !important;
            text-decoration: underline;
          }
        }
      }
    }
  }
  
  @media (max-width: 1024px) and (min-width: 320px) {
    .commits {
      width: 100%;
      overflow: hidden;
      margin: 0px auto;
      padding: 0px;
  
      .centers-el {
        width: 99.99%;
        box-sizing: border-box;
        overflow: hidden;
        margin: 48px auto auto auto;
  
        .left {
          width: 100%;
          overflow: hidden;
          margin:auto auto 10px auto;
          box-sizing: border-box;
        }
  
        .right {
          width: 100%;
          overflow: hidden;
          margin: 10px auto;
          background: #fff;
          -webkit-box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
          box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
  
          .hot_lists {
            width: 100%;
            margin: 10px auto;
            border-radius: 2px;
            -webkit-box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
            box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
            background: #fff;
            font-size: $fontJ;
            box-sizing: border-box;
            padding: 10px;
            overflow: hidden;
  
            .hot_top_tites {
              width: 100%;
              overflow: hidden;
              margin: 0px auto;
              color: rgb(217, 83, 80);
              font-size: 14px;
            }
          }
        }
  
      }
  
      .page-el {
        width: 100%;
        overflow: hidden;
        margin: 10px auto;
      }
  
      .nav-topbar {
        width: 100%;
        overflow: hidden;
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 10;
        background: url('/imgs/fade.png') top center repeat-x;
  
        .mobiles-nav-list {
          width: 100%;
          overflow: hidden;
          box-sizing: border-box;
          padding: 0px;
          margin: 0px auto;
  
          .nav-backs {
            text-decoration: none;
            padding: 1px;
            display: block;
            margin-left: 10px;
            font-weight: 400;
            overflow: hidden;
            color: maroon;
            line-height: 45px;
            text-align: center;
            float: left;
          }
        }
  
        .nav-list {
          display: none;
        }
      }
    }
  
  }
  </style>