<template>
  <div class="ResLists">
    <div class="nav-topbar">
      <div class="mobiles-nav-list">
        <a class="nav-backs" @click="backs()"> 返回 </a>
      </div>
      <div class="nav-list">
        <a class="nav-backs" @click="backs()"> 返回 </a>
        <a class="nav-items">我的视图 </a>
      </div>
    </div>
    <div class="centers-el">
      <div class="left">
        <el-table :data="List" style="width: 100%">
          <el-table-column prop="createtime" label="创建日期"> </el-table-column>
          <el-table-column prop="title" label="标题"> </el-table-column>
          <el-table-column prop="user" label="头像">
            <template slot-scope="scope">
              <el-avatar size="large" :src="'https://sosunlive.com' + scope.row.user.avatar"></el-avatar>
            </template>
          </el-table-column>
          <el-table-column prop="user" label="昵称">
            <template slot-scope="scope">
              <div>{{ scope.row.user.username }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="status" label="状态">
            <template slot-scope="scope">
              <div v-if="scope.row.status == 1">审核通过</div>
              <div v-if="scope.row.status == 0">待审核</div>
              <div v-if="scope.row.status == 2">审核不通过</div>
            </template>
          </el-table-column>
          <!--1=图片,2=视频,3=附件-->
          <el-table-column prop="type" label="类型">
            <template slot-scope="scope">
              <div v-if="scope.row.category_id == 34">图片</div>
              <div v-if="scope.row.category_id == 35">视频</div>
              <div v-if="scope.row.category_id == 4">云盘</div>
              <div v-if="scope.row.category_id == 50">网址</div>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作">
            <template slot-scope="scope">
              <el-button @click.native.prevent="shows(scope.row)" type="text" size="small">
                详情
              </el-button>
              <el-button @click.native.prevent="DelRow(scope.row)" type="text" size="small">
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="page-el">
          <el-pagination background @current-change="handleCurrentChange" :current-page="currentPage"
            :page-size="pageSize" layout="total,prev,pager,next" :total="total">
          </el-pagination>
        </div>
      </div>
      <div class="right">
        <div class="hot_lists">
          <div class="hot_top_tites">很想很想你的热榜 · · · · · ·</div>
          <HotItem :hotList="hotList" :hotTotal="hotTotal" @handleHotCurrentChange="handleHotCurrentChange"></HotItem>
        </div>
      </div>
    </div>
    <el-dialog title="" :visible.sync="dialogFormVisible" :width="width">
      <div slot="title" v-if="info.category_id==35" class="header-title" :style="{ 'background': '#f2f2f2', 'color': '#6a6a6a' }">
        <div style="padding:20px 20px;">视频</div>
      </div>
      <div slot="title" v-if="info.category_id==50" class="header-title" :style="{ 'background': '#f2f2f2', 'color': '#6a6a6a' }">
        <div style="padding:20px 20px;">网址</div>
      </div>
      <div slot="title" v-if="info.category_id==34" class="header-title" :style="{ 'background': '#f2f2f2', 'color': '#6a6a6a' }">
        <div style="padding:20px 20px;">图片</div>
      </div>
      <div class="dialog-body">
        <div class="commit-center">
          <video controls :src="baseUrls + info.attachfile" v-if="info.category_id == 35"
            style="width: 100%; overflow: hidden; margin: 0px auto" />
          <div style="width: 100%; overflow: hidden; margin: 0px auto" v-if="info.category_id == 34">
            <el-image v-for="(val, index) in info.images" :key="index" :src="baseUrls + val" class="img-item" />
          </div>
          <div style="width: 100%; overflow: hidden; margin: 0px auto" v-if="info.type == 4">
            <div>
              <el-link :href="info.yunlink" target="_blank">{{ info.yunlink }}</el-link>
            </div>
            <div>云盘分享码：{{ info.yuncode }}</div>
          </div>
          <div style="width: 100%; overflow: hidden; margin: 0px auto" v-if="info.category_id == 50">
            <el-link :href="info.weblink" target="_blank">{{ info.weblink }}</el-link>
          </div>
        </div>
      </div>
    </el-dialog>

  </div>
</template>
<script>
import HotItem from '@/components/HotItem';
import ApiCon from "../util/Api/index";
import axios from "axios";
export default {
  name: "Reply",
  data() {
    return {
      List: [],
      hotList: [],
      hotTotal: 0,
      vals: "",
      pageSize: 14,
      width: "50%",
      baseUrls: "https://www.sosunlive.com",
      info: {},
      total: 1,
      hotList:[],
      category_ids:'',
      hotTotal:0,
      currentPage: 1,
      page: 1,
      dialogFormVisible: false,
    };
  },
  components: {
    HotItem
  },
  created() {
    let self = this;
    let category_ids = localStorage.getItem("category_ids")
    if (category_ids) {
      this.category_ids = category_ids
    }else{
      this.category_ids = '45'
    }
    self.init();
    if (window.screen.width > 430) {
      self.width = "50%";
    } else {
      self.width = "98%";
    }
  },
  methods: {
    shows(val) {
      let self = this;
      self.dialogFormVisible = true;
      self.info = val;
    },
    handleHotCurrentChange(val) {
      this.hotList = [];
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      this.$api.mall
        .getHotList({
          type: this.category_ids,
          page: val,
          limit: this.pageSize,
        })
        .then((ress) => {
          let res = ress.data;
          for (var i = 0; i < res.data.data.length; i++) {
            res.data.data[i].shows = true;
            res.data.data[i].tag = res.data.data[i].tag.split(",");
          }
          this.hotTotal = res.data.total;
          this.hotList = res.data.data;
        });
    },
    getHotLists() {
      this.hotList = [];
      this.$api.mall
        .getHotList({
          page: 1,
          type: this.category_ids,
          limit: this.pageSize,
        })
        .then((ress) => {
          let res = ress.data;
          for (var i = 0; i < res.data.data.length; i++) {
            res.data.data[i].shows = true;
            res.data.data[i].tag = res.data.data[i].tag.split(",");
          }
          this.hotTotal = res.data.total;
          this.hotList = res.data.data;
        });
    },
    init() {
      //speaUserList
      this.List = [];
      this.currentPage = 1;
      this.$api.mall
        .resourcesMyList({
          page: 1,
          limit: 14,
        })
        .then((ress) => {
          let res = ress.data;
          this.List = res.data.data;
          this.total = res.data.total;
          this.getHotLists()
        });
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      document.documentElement.scrollTop = 0;
      this.list = [];
      this.$api.mall
        .resourcesMyList({
          page: this.currentPage,
          limit: 14,
        })
        .then((ress) => {
          let res = ress.data;
          this.List = res.data.data;
          this.total = res.data.total;
        });
    },
    backs() {
      history.go(-1);
    },
    DelRow: function (val) {
      let self = this;

      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$api.mall
            .resourcesdel({
              id: val.id,
            })
            .then((res) => {
              console.log(res);
              if (res.code == 1) {
                self.$message.success("删除成功");
                self.init();
              } else {
                self.$message.error("删除失败，请稍后再试");
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@media (max-width: 1920px) and (min-width: 1024px) {
  .ResLists {
    width: 100%;
    overflow: hidden;
    margin: 0px auto;
    padding: 0px;

    .img-item {
      margin-left: 10px;
      margin-top: 10px;
      width: 168px;
      height: auto;
      float: left;
      display: block;
    }

    .centers-el {
      width: 980px;
      overflow: hidden;
      margin: 45px auto auto auto;
      
      .left {
        width: 680px;
        overflow: hidden;
        float: left;
        box-sizing: border-box;
      }

      .right {
        width: 280px;
        overflow: hidden;
        background: #fff;
        -webkit-box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
        box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        float: right;

        .hot_lists {
          width: 100%;
          margin: 10px auto;
          border-radius: 2px;
          -webkit-box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
          box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
          background: #fff;
          font-size: $fontJ;
          box-sizing: border-box;
          padding: 10px;
          overflow: hidden;

          .hot_top_tites {
            width: 100%;
            overflow: hidden;
            margin: 0px auto;
            color: rgb(217, 83, 80);
            font-size: 14px;
          }
        }
      }
    }

    .page-el {
      width: 100%;
      overflow: hidden;
      padding: 0px;
      margin: 10px auto;
    }

    .nav-topbar {
      width: 100%;
      overflow: hidden;
      background: #ffe url('/imgs/fade.png') top center repeat-x;
      position: fixed;
      top: 0px;
      left: 0px;
      z-index: 10;

      .mobiles-nav-list {
        display: none;
      }

      .nav-list {
        width: 980px;
        overflow: hidden;
        margin: 0px auto;

        .searchTexts {
          width: 50%;
          float: right;
          overflow: hidden;
          margin: 6px auto;
        }

        .nav-backs {
          text-decoration: none;
          display: block;
          font-weight: 400;
          overflow: hidden;
          color: maroon;
          line-height: 45px;
          text-align: center;
          float: left;
          cursor: pointer;
        }

        .nav-items {
          text-decoration: none;
          display: block;
          margin-left: 12px;
          font-weight: 400;
          overflow: hidden;
          color: maroon;
          line-height: 45px;
          text-align: center;
          float: left;
        }

        .nav-backs:hover {
          color: red !important;
          text-decoration: underline;
        }
      }
    }
  }
}

@media (max-width: 1024px) and (min-width: 320px) {
  .ResLists {
    width: 100%;
    overflow: hidden;
    margin: 0px auto;
    padding: 0px;

    .img-item {
      margin-left: 1%;
      margin-top: 1%;
      width: 49%;
      height: auto;
      float: left;
      display: block;
    }
    .page-el {
      width: 100%;
      overflow: hidden;
      padding: 0px;
      margin: 10px auto;
    }
    .centers-el {
      width: 99.99%;
      box-sizing: border-box;
      overflow: hidden;
      margin: 45px auto auto auto;
      
      .left {
        width: 100%;
        overflow: hidden;
        margin: 0px auto;
        box-sizing: border-box;
      }

      .right {
        width: 100%;
        overflow: hidden;
        background: #fff;
        -webkit-box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
        box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        margin: 10px auto;

        .hot_lists {
          width: 100%;
          margin: 10px auto;
          border-radius: 2px;
          -webkit-box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
          box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
          background: #fff;
          font-size: $fontJ;
          box-sizing: border-box;
          padding: 10px;
          overflow: hidden;

          .hot_top_tites {
            width: 100%;
            overflow: hidden;
            margin: 0px auto;
            color: rgb(217, 83, 80);
            font-size: 14px;
          }
        }
      }
    }

    .nav-topbar {
      width: 100%;
      overflow: hidden;
      position: fixed;
      top: 0px;
      left: 0px;
      z-index: 10;
      background: url('/imgs/fade.png') top center repeat-x;

      .mobiles-nav-list {
        width: 100%;
        overflow: hidden;
        box-sizing: border-box;
        padding: 0px;
        margin: 0px auto;

        .nav-backs {
          text-decoration: none;
          display: block;
          margin-left: 12px;
          font-weight: 400;
          overflow: hidden;
          color: maroon;
          line-height: 45px;
          text-align: center;
          float: left;
        }
      }

      .nav-list {
        display: none;
      }
    }
  }
}
</style>
