<template>
  <div class="commits">
    <div class="nav-topbar">
      <div class="mobiles-nav-list">
        <a class="nav-backs" @click="backs()"> 返回 </a>
      </div>
      <div class="nav-list">
        <a class="nav-backs" @click="backs()"> 返回 </a>
        <a class="nav-items">我的评论 </a>
      </div>
    </div>
    <div class="centers-el">
      <div class="commit-center">
        <div class="left">
          <div class="commit-items" v-if="List.length == 0" style="text-align:center">暂无评论</div>
          <div class="commit-items" v-for="(vals, index) in List" :key="index">
            <el-row :gutter="20" class="el-row">
              <el-col :span="24">
                <el-avatar :src="baseUrl + vals.user.avatar" shape="square" :size="28" fit="contain"></el-avatar>
              </el-col>
              <el-col :span="24"><span>{{ vals.user.username }}</span></el-col>
            </el-row>
            <div class="commit-items" v-if="vals.replyuser">
              <el-row :gutter="20" class="el-row">
                <el-col :span="24">
                  >>
                </el-col>
                <el-col :span="24">
                  <el-avatar :src="baseUrl + vals.replyuser.avatar" shape="square" :size="28" fit="contain"></el-avatar>
                </el-col>
                <el-col :span="24"><span>{{ vals.replyuser.username }}</span></el-col>
              </el-row>
            </div>
            <div class="maincontent" v-html="vals.maincontent"></div>
            <div v-html="vals.createtime"></div>
            <div style="margin-top:12px">
              <el-button type="warning" plain @click="shows(vals)" size="mini">查看回复</el-button>
              <el-button type="danger" plain @click="dels(vals)" size="mini" style="margin-left:10px">删除</el-button>
            </div>
          </div>
          <div class="page-el">
            <el-pagination background @current-change="handleCurrentChange" :current-page="currentPage"
              :page-size="pageSize" layout="total,prev,pager,next" :total="total">
            </el-pagination>
          </div>
        </div>

        <div class="right">
          <div class="hot_lists">
            <div class="hot_top_tites">很想很想你的热榜 · · · · · ·</div>
            <HotItem :hotList="hotList" :hotTotal="hotTotal" @handleHotCurrentChange="handleHotCurrentChange"></HotItem>
          </div>
        </div>
      </div>

    </div>

    <el-dialog title="" :visible.sync="dialogFormVisible" :width="width">
      <div class="dialog-body">
        <div class="commit-center" v-for="(vals, index) in rList" :key="index">
          <div class="commit-items" v-if="rList.length == 0" style="text-align:center">暂无评论</div>
          <div class="commit-items">
            <el-row :gutter="20" class="el-row">
              <el-col :span="24">
                <el-avatar :src="baseUrl + vals.user.avatar" shape="square" :size="28" fit="contain"></el-avatar>
              </el-col>
              <el-col :span="24"><span>{{ vals.user.username }}</span></el-col>
            </el-row>
            <div class="commit-items" v-if="vals.replyuser" style="margin-left:12px">
              <el-row :gutter="20" class="el-row">
                <el-col :span="24">
                  >>
                </el-col>
                <el-col :span="24">
                  <el-avatar :src="baseUrl + vals.replyuser.avatar" shape="square" :size="28" fit="contain"></el-avatar>
                </el-col>
                <el-col :span="24"><span>{{ vals.replyuser.username }}</span></el-col>
                <el-col :span="24">
                  <div class="maincontent" v-html="vals.replymaincontent"></div>
                </el-col>
              </el-row>
            </div>
            <div class="maincontent" v-html="vals.maincontent"></div>
            <div v-html="vals.createtime"></div>
            <div style="margin-top:12px">
              <el-button type="warning" plain @click="processs = vals" size="mini">回复</el-button>
            </div>
            <div v-show="processs == vals">
              <vue-editor id="editor" class="editor" useCustomImageHandler @image-added="handleImageAdded"
                v-model="content" :editor-toolbar="customToolbar"> </vue-editor>
              <el-button type="warning" plain @click="submit()" size="mini">提交</el-button>
              <el-button type="danger" @click="processs = ''" plain size="mini">收回</el-button>
            </div>
          </div>
        </div>
        <el-button type="primary" plain @click="getpicklist()" size="mini" style="margin-top:12px">更多</el-button>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="warning" @click="dialogFormVisible = false">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { VueEditor } from "vue2-editor"
import ApiCon from '../util/Api/index'
import HotItem from '@/components/HotItem';
import axios from "axios"
export default {
  name: 'Reply',
  data() {
    return {
      List: [],
      rList: [],
      currentPage: 0,
      content: '',
      
      processs: '',
      process: '',
      width: '',
      baseUrl: '',
      total: 0,
      hotList: [],
      category_ids: '',
      hotTotal: 0,
      rtotal: 0,
      rpage: 1,
      currentPage: 1,  // 当前页码
      pageSize: 10,  // 每页显示的行数
      dialogFormVisible: false,
      customToolbar: [
        [{ 'header': [false, 1, 2, 3, 4, 5, 6] }], //标题
        ['bold', 'italic', 'underline'], //加粗\斜体\下划线
        ['italic'], //斜体----也可以拆开来写，用于换位置顺序
        [{ 'align': '' }, { 'align': 'center' }, { 'align': 'right' }], //对齐
        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' }], //有序无序列表
        [{ 'background': [] }, { 'color': [] }], //背景色
        ['image', 'link'], //图片、链接
        ['strike'], //作废（文字中间一横）
        ['underline'],
        ['clean'], //清楚格式（所谓去掉背景色这些花里胡巧）
        ['code'], //代码
        ['code-block'] //代码块
        //['video'] 视频
      ],

    }
  },
  created() {
    this.ApiCon = new ApiCon(this.$store)
    this.baseUrl = this.ApiCon.getApiUrl()
    this.init()
    let category_ids = localStorage.getItem("category_ids")
    if (category_ids) {
      this.category_ids = category_ids
    }else{
      this.category_ids = '45'
    }
    if (window.screen.width > 1024) {
      this.width = '50%'
    } else {
      this.width = "99.99%"
    }
  },
  mounted() {
    this.ApiCon = new ApiCon(this.$store)
    this.ApiConUrl = this.ApiCon.getApiUrl()
  },

  components: {
    VueEditor,
    HotItem
  },
  methods: {
    backs: function () {
      this.$router.push("/community");
    },
    handleHotCurrentChange(val) {
      this.hotList = [];
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      this.$api.mall
        .getHotList({
          type: this.category_ids,
          page: val,
          limit: this.pageSize,
        })
        .then((ress) => {
          let res = ress.data;
          for (var i = 0; i < res.data.data.length; i++) {
            res.data.data[i].shows = true;
            res.data.data[i].tag = res.data.data[i].tag.split(",");
          }
          this.hotTotal = res.data.total;
          this.hotList = res.data.data;
        });
    },
    getHotLists() {
      this.hotList = [];
      this.$api.mall
        .getHotList({
          page: 1,
          type: this.category_ids,
          limit: this.pageSize,
        })
        .then((ress) => {
          let res = ress.data;
          for (var i = 0; i < res.data.data.length; i++) {
            res.data.data[i].shows = true;
            res.data.data[i].tag = res.data.data[i].tag.split(",");
          }
          this.hotTotal = res.data.total;
          this.hotList = res.data.data;
        });
    },
    getpicklist() {
      let self = this
      self.rpage = self.rpage + 1
      this.$api.mall.getPickList({
        pick: self.process.id,
        page: self.rpage,
        limit: 14,
      }).then(ress => {
        let res = ress.data
        console.log(res)
        if (res.data.data.length == 0) {
          self.$message.error("没有更多回复")
          return
        }
        for (var i = 0; i < res.data.data.length; i++) {
          this.rList.push(res.data.data[i])
        }
      });
    },
    submit() {
      let self = this
      if (!self.content) {
        self.$message.error("请您填入内容")
        return
      }
      this.$api.mall.speaAdd({
        maincontent: self.content,
        isreply: 2,
        media_id: self.processs.media_id,
        pick: self.processs.pick,
        //pickuser: self.process.pickuser.id,
        reply: self.processs.id,
        replyuser: self.processs.user.id
      }).then(res => {
        console.log(res)
        if (res.code == 1) {
          self.$message.success("成功")
          self.rpage=1
          self.shows(self.process)
        } else {
          self.$message.error("失败")
        }
      });
    },
    dels(val) {
      //speakdel
      let self = this
      this.$api.mall.speakdel({
        id: val.id,
      }).then(res => {
        if (res.code == 1) {
          self.$message.success("成功")
          self.init()
        } else {
          self.$message.error("失败")
        }
      });
    },
    shows(val) {
      this.process = val
      this.rList = []
      this.$api.mall.getPickList({
        pick: val.id,
        page: 1,
        limit: 14,
      }).then(ress => {
        let res = ress.data
        if (res.data.data.length == 0) {
          this.$message.error("亲，你的评论没有更多回复")
          return
        }
        this.rList = res.data.data
        this.rtotal = res.data.current_page
        this.dialogFormVisible = true
      });

    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    async handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      let self = this
      // An example of using FormData
      // NOTE: Your key could be different such as:
      // formData.append('file', file)
      var formData = new FormData();
      formData.append("file", file);
      axios({
        url: "https://sosunlive.com/api/common/upload",
        method: "POST",
        headers: {
          'Token':window.localStorage.getItem("token")
        },
        data: formData
      })
        .then(result => {
          //  console.log(result, "009966")  self.baseUrl + 
          let url = result.data.data.url; // Get url from response
          Editor.insertEmbed(cursorLocation, "image", url);
          resetUploader();
        })
        .catch(err => {
          console.log(err);
        });
    },
    updateData(e) {
      this.content = e;
    },
    handleCurrentChange(val) {
      this.currentPage = val
      //console.log(this.ApiCon.gets('pages'))
      //this.$router.push("/cart");
      document.documentElement.scrollTop = 0
      this.list = []
      this.$api.mall.getPickUserList({
        page: this.currentPage,
        limit: this.pageSize
      }).then(res => {
        for (var i = 0; i < res.data.data.length; i++) {
          res.data.data[i].shows = true
        }
        this.total = res.data.current_page
        this.List = res.data.data
      });
    },
    init() {
      //speaUserList
      this.List = []
      this.$api.mall.getPickUserList({
        page: 1,
        limit: 14,
      }).then(ress => {
        let res = ress.data
        this.List = res.data.data
        this.total = res.data.current_page
        this.getHotLists()
      });

    }
  }
}
</script>
<style lang="scss" scoped>
@media (max-width: 8000px) and (min-width: 1024px) {
  .commits {
    width: 100%;
    overflow: hidden;
    margin: 0px auto;
    padding: 0px;

    .commit-center {
      width: 100%;
      overflow: hidden;
      margin: 10px auto;

      .maincontent {
        width: 288px;
        padding: 0px;
        margin-top: 10px;
        margin-bottom: 10px;
        overflow: hidden;
        box-sizing: border-box;
      }

      .left {
        width: 680px;
        overflow: hidden;
        background: #fff;
        float: left;
        box-sizing: border-box;
      }

      .right {
        width: 280px;
        overflow: hidden;
        background: #fff;
        -webkit-box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
        box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        float: right;

        .hot_lists {
          width: 100%;
          margin: 10px auto;
          border-radius: 2px;
          -webkit-box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
          box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
          background: #fff;
          font-size: $fontJ;
          box-sizing: border-box;
          padding: 10px;
          overflow: hidden;

          .hot_top_tites {
            width: 100%;
            overflow: hidden;
            margin: 0px auto;
            color: rgb(217, 83, 80);
            font-size: 14px;
          }
        }
      }

      .commit-items {
        width: 100%;
        overflow: hidden;
        margin: 12px auto;
        border-radius: 2px;
        -webkit-box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
        box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        padding: 16px 15px 15px;

        p {
          padding: 0px;
          margin: 0px;
        }
      }
    }

    .el-row {
      margin-bottom: 10px;
    }

    .centers-el {
      width: 980px;
      overflow: hidden;
      margin: 45px auto auto auto;

    }

    .page-el {
      width: 980px;
      overflow: hidden;
      margin: 10px auto;
    }

    .nav-topbar {
      width: 100%;
      overflow: hidden;
      background: #ffe url('/imgs/fade.png') top center repeat-x;
      position: fixed;
      top: 0px;
      left: 0px;
      z-index: 10;

      .mobiles-nav-list {
        display: none;
      }

      .nav-list {
        width: 980px;
        overflow: hidden;
        margin: 0px auto;

        .searchTexts {
          width: 50%;
          float: right;
          overflow: hidden;
          margin: 6px auto;
        }

        .nav-backs {
          text-decoration: none;
          display: block;
          font-weight: 400;
          overflow: hidden;
          color: maroon;
          line-height: 45px;
          text-align: center;
          float: left;
          cursor: pointer;
        }

        .nav-items {
          text-decoration: none;
          display: block;
          margin-left: 12px;
          font-weight: 400;
          overflow: hidden;
          color: maroon;
          line-height: 45px;
          text-align: center;
          float: left;
        }

        .nav-backs:hover {
          color: red !important;
          text-decoration: underline;
        }
      }
    }
  }
}

@media (max-width: 1024px) and (min-width: 320px) {
  .commits {
    width: 100%;
    overflow: hidden;
    margin: 0px auto;
    padding: 0px;


    .commit-center {
      width: 100%;
      overflow: hidden;
      margin: 0px auto;

      .maincontent {
        width: 100%;
        padding: 0px;
        overflow: hidden;
        margin-top: 10px;
        margin-bottom: 10px;
        box-sizing: border-box;
      }

      .left {
        width: 100%;
        overflow: hidden;
        margin:0px auto;
        box-sizing: border-box;

        .page-el {
          width: 100%;
          overflow: hidden;
          box-sizing: border-box;
          margin: 10px auto;
        }


      }

      .right {
        width: 100%;
        overflow: hidden;
        background: #fff;
        -webkit-box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
        box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        margin: 12px auto;

        .hot_lists {
          width: 100%;
          margin: 10px auto;
          border-radius: 2px;
          -webkit-box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
          box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
          background: #fff;
          font-size: $fontJ;
          box-sizing: border-box;
          padding: 10px;
          overflow: hidden;

          .hot_top_tites {
            width: 100%;
            overflow: hidden;
            margin: 0px auto;
            color: rgb(217, 83, 80);
            font-size: 14px;
          }
        }
      }

      .commit-items {
        width: 100%;
        overflow: hidden;
        margin: 0px auto;
        background: #fff;
        border-radius: 2px;
        -webkit-box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
        box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        padding: 16px 15px 15px;

        p {
          padding: 0px;
          margin: 0px;
        }
      }
    }

    .el-row {
      margin-bottom: 10px;
    }

    .centers-el {
      width: 99%;
      box-sizing: border-box;
      overflow: hidden;
      margin: 45px auto auto auto;

    }

    .nav-topbar {
      width: 100%;
      overflow: hidden;
      position: fixed;
      top: 0px;
      left: 0px;
      z-index: 10;
      background: url('/imgs/fade.png') top center repeat-x;

      .mobiles-nav-list {
        width: 100%;
        overflow: hidden;
        box-sizing: border-box;
        padding: 0px;
        margin: 0px auto;

        .nav-backs {
          text-decoration: none;
          display: block;
          margin-left: 12px;
          font-weight: 400;
          overflow: hidden;
          color: maroon;
          line-height: 45px;
          text-align: center;
          float: left;
        }
      }

      .nav-list {
        display: none;
      }
    }
  }

}
</style>