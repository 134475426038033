import { render, staticRenderFns } from "./Reply.vue?vue&type=template&id=4b1fc4dc&scoped=true"
import script from "./Reply.vue?vue&type=script&lang=js"
export * from "./Reply.vue?vue&type=script&lang=js"
import style0 from "./Reply.vue?vue&type=style&index=0&id=4b1fc4dc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b1fc4dc",
  null
  
)

export default component.exports