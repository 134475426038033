<template>
    <div class="commits">
      <div class="nav-topbar">
      <div class="mobiles-nav-list">
        <a class="nav-backs" @click="backs()"> 返回 </a>
      </div>
      <div class="nav-list">
        <a class="nav-backs" @click="backs()"> 返回 </a>
        <a class="nav-items" >回复我的 </a>
        </div>
      </div>
      <div class="centers-el">

        <div class="commit-center">
          <div v-if="List.length == 0" style="text-align:center">暂无评论</div>
          <div class="commit-items" v-for="(vals, index) in List" :key="index">
            <el-row :gutter="20" class="el-row">
              <el-col :span="24">
                <el-avatar :src="baseUrl + vals.user.avatar" shape="square" :size="28" fit="contain"></el-avatar>
              </el-col>
              <el-col :span="24"><span>{{ vals.user.username }}</span></el-col>
            </el-row>
            
            <div class="commit-items" v-if="vals.replyuser"  style="margin-left: 12px;">
               <el-row :gutter="20" class="el-row" >
                  <el-col :span="24">
                    >>
                  </el-col>
                  <el-col :span="24">
                    <el-avatar :src="baseUrl + vals.replyuser.avatar" shape="square" :size="28" fit="contain"> </el-avatar>
                  </el-col>
                <el-col :span="24"><span>{{ vals.replyuser.username }}</span></el-col>
                <el-col :span="24"><div class="maincontent"  v-html="vals.replymaincontent"></div></el-col>
               </el-row>
            </div>
            <div class="maincontent"  v-html="vals.maincontent"></div>
            <div v-html="vals.createtime"></div>
            <div style="margin-top:12px">
               <el-button type="warning" plain @click="process=vals" size="mini">回复</el-button>
               <div v-show="process == vals">
                <vue-editor id="editor" class="editor" useCustomImageHandler @image-added="handleImageAdded"
                    v-model="content" :editor-toolbar="customToolbar"> </vue-editor>
                <el-button type="warning" plain @click="submit()" size="mini" >提交</el-button>
                <el-button type="danger" @click="process = ''" plain size="mini">收回</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="page-el">
        <el-pagination background @current-change="handleCurrentChange" :current-page="currentPage"
          :page-size="pageSize" layout="total,prev,pager,next" :total="total">
        </el-pagination>
      </div>
  </div>
</template>
<script>
import { VueEditor } from "vue2-editor"
import ApiCon from '../util/Api/index'
import axios from "axios"
export default {
  name: 'Reply',
  data() {
    return {
      List:[],
      rList:[],
      currentPage:0,
      content:'',
      process: '',
      baseUrl: '',
      total:0,
      rtotal:0,
      rpage:1,
      currentPage: 1,  // 当前页码
      pageSize: 10,  // 每页显示的行数
      dialogFormVisible:false,
      customToolbar: [
        [{ 'header': [false, 1, 2, 3, 4, 5, 6] }], //标题
        ['bold', 'italic', 'underline'], //加粗\斜体\下划线
        ['italic'], //斜体----也可以拆开来写，用于换位置顺序
        [{ 'align': '' }, { 'align': 'center' }, { 'align': 'right' }], //对齐
        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' }], //有序无序列表
        [{ 'background': [] }, { 'color': [] }], //背景色
        ['image', 'link'], //图片、链接
        ['strike'], //作废（文字中间一横）
        ['underline'],
        ['clean'], //清楚格式（所谓去掉背景色这些花里胡巧）
        ['code'], //代码
        ['code-block'] //代码块
        //['video'] 视频
      ],
      
    }
  },
  created(){
    
    this.ApiCon = new ApiCon(this.$store)
    this.baseUrl = this.ApiCon.getApiUrl()
    this.init()
  },
  mounted() {
    this.ApiCon = new ApiCon(this.$store)
    this.ApiConUrl = this.ApiCon.getApiUrl()
  },
  components: {
    VueEditor
  },
  methods:{
    backs:function(){
      this.$router.push("/community");
    },
  submit(){
      let self = this
      if(!self.content){
        self.$message.error("请您填入内容")
        return
      }
      this.$api.mall.speaAdd({
        maincontent: self.content,
        isreply: 2,
        ids: self.process.ids,
        pick:self.process.pick,
        pickuser:self.process.pickuser.id,
        reply: self.process.id,
        replyuser: self.process.user.id
      }).then((res) => {
        console.log(res)
        if (res.code == 1) {
          self.$message.success("成功")
        } else {
          self.$message.error("失败")
        }
      });
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    async handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      let self = this
      // An example of using FormData
      // NOTE: Your key could be different such as:
      // formData.append('file', file)
      var formData = new FormData();
      formData.append("file", file);
      axios({
        url: "https://sosunlive.com/api/common/upload",
        method: "POST",
        headers: {
          'Token':window.localStorage.getItem("token")
        },
        data: formData
      })
        .then(result => {
          console.log(result, "009966") //self.baseUrl +
          let url =  'https://www.sosunlive.com'+result.data.data.url; // Get url from response
          Editor.insertEmbed(cursorLocation, "image", url);
          resetUploader();
        })
        .catch(err => {
          console.log(err);
        });
    },
    updateData(e) {
      this.content = e;
    },
    handleCurrentChange(val) {
      this.currentPage = val
      //console.log(this.ApiCon.gets('pages'))
      //this.$router.push("/cart");
      document.documentElement.scrollTop = 0
      this.list = []
      this.$api.mall.getReplyUserList({
        page:this.currentPage,
        limit:this.pageSize
      }).then((res) => {
        for (var i = 0; i < res.data.data.length; i++) {
            res.data.data[i].shows = true
        }
        this.total = res.data.total
        this.List = res.data.data
      });
    },
    init(){
      //speaUserList
      this.List = []
      this.$api.mall.getReplyUserList({
        page: 1,
        limit: 14,
      }).then((ress) => {
        let res=ress.data
        this.List = res.data.data
        this.total = res.data.total
      });
    
    }
  }
}
</script>
<style lang="scss" scoped>

@media (max-width: 8000px) and (min-width: 1024px) {
.commits{
    width: 100%;
    overflow: hidden;
    margin: 0px auto;
    padding: 0px;
    .commit-center {
      width: 100%;
      overflow: hidden;
      margin: 0px auto;
      .maincontent{
        box-sizing: border-box;
        width: 288px;
        overflow: hidden;
        margin-top: 10px;
        padding: 0px;
      }
      .commit-items {
        width: 100%;
        overflow: hidden;
        margin: 0px auto;
        background: #fff;
        border-radius: 2px;
        -webkit-box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
        box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        padding: 16px 15px 10px;

        p {
          padding: 0px;
          margin: 0px;
        }
      }
    }
  
    .el-row {
      margin-bottom: 10px;
    }
    .centers-el{
      width: 980px;
      overflow: hidden;
      margin:45px auto auto auto;
     
     }
    .page-el{
      width: 980px;
      overflow: hidden;
      margin:10px auto auto auto;
    }
    .nav-topbar {
      width: 100%;
      overflow: hidden;
      background: #ffe url('/imgs/fade.png') top center repeat-x;
      position: fixed;
      top: 0px;
      left: 0px;
      z-index: 10;
      .mobiles-nav-list{
        display: none;
      }
      .nav-list{
        width: 980px;
        overflow: hidden;
        margin: 0px auto;
        .searchTexts {
          width: 50%;
          float: right;
          overflow: hidden;
          margin: 6px auto;
        }
        .nav-backs {
          text-decoration: none;
          display: block;
          font-weight: 400;
          overflow: hidden;
          color: maroon;
          line-height: 45px;
          text-align: center;
          float: left;
          cursor: pointer;
        }

        .nav-items {
          text-decoration: none;
          display: block;
          font-size: 14px;
          margin-left: 12px;
          font-weight: 400;
          overflow: hidden;
          color: maroon;
          line-height: 45px;
          text-align: center;
          float: left;
        }

        .nav-backs:hover {
          color: red !important;
          text-decoration: underline;
        }
      }
    }
  }
}

@media (max-width: 1024px) and (min-width: 320px) {
  .commits{
    width: 100%;
    overflow: hidden;
    margin: 0px auto;
    padding: 0px;
    .commit-center {
      width: 100%;
      overflow: hidden;
      margin: 0px auto;
      .maincontent{
        box-sizing: border-box;
        width: 100%;
        overflow: hidden;
        margin-top: 10px;
        padding: 0px;
      }

      .commit-items {
        width: 100%;
        overflow: hidden;
        margin: 0px auto;
        background: #fff;
        border-radius: 2px;
        -webkit-box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
        box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        padding: 16px 15px 10px;

        p {
          padding: 0px;
          margin: 0px;
        }
      }
    }

  
    .el-row {
      margin-bottom: 10px;
    }
    .centers-el{
      width:99%;
      box-sizing: border-box;
      overflow: hidden;
      margin:45px auto auto auto;
    
     }

    .nav-topbar {
      width: 100%;
      overflow: hidden;
      position: fixed;
      top: 0px;
      left: 0px;
      z-index: 10;
      background: url('/imgs/fade.png') top center repeat-x;

      .mobiles-nav-list {
        width: 100%;
        overflow: hidden;
        box-sizing: border-box;
        margin: 0px auto;

        .nav-backs {
          text-decoration: none;
          display: block;
          margin-left: 10px;
          font-weight: 400;
          overflow: hidden;
          color: maroon;
          line-height: 45px;
          text-align: center;
          float: left;
        }
      }
      .nav-list {
          display: none;
        }
     }
  }

}

</style>