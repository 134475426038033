<template>
  <div class="detail-form">
    <div class="nav-topbar">
      <div class="mobiles-nav-list">
        <div class="nav-backs" @click="backgo"> 返回 </div>
        <div class="nav-items">
          <el-input placeholder="请输入内容" prefix-icon="el-icon-search" size="medium" style="width: 288px;"
            v-model="searchText" @input="init"></el-input>
        </div>
      </div>
      <div class="nav-list">
        <div class="dict-top">
          <div class="hot_centers">
            <div class="hot_items" @click="backgo">
              返回
            </div>
            <div class="hot_items" v-for="(val, index) in searchList" :key="index" @click="setHot(val)">
              <span>{{ val }}</span>
            </div>
            <div class="hot_items">
              <el-input placeholder="请输入内容" prefix-icon="el-icon-search" size="medium" style="width: 288px;"
                v-model="searchText" @input="init"></el-input>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="nav-products" v-show="List.length>0">
      <div class="products-list">
        <div class="products-item" v-for="(val, index) in List" :key="index" @click="navTo(val)">
          <div class="products-image">
            <el-image :src="baseUrls + val.image" style="width:68px;height: auto;"></el-image>
          </div>
          <div class="products-title">{{ val.title }} - {{ val.createtime }}</div>
        </div>
      </div>
    </div>
    <div class="title-cneters">
      <div class="title-top-content">
        <div class="tag-centers">
          <div class="tag-items-title">
            话题：
          </div>
          <div class="tag-items" v-for="(val, index) in info.tag" :key="index" @click="setHot(val)">
            {{ val }}
          </div>
        </div>
        <div class="titles">{{ info.title }}</div>
      </div>
    </div>
    <div class="App-main">
      <div class="left">
        <div class="media-center" v-if="info.attachfile">
          <video-player class="video-player vjs-custom-skin" ref="videoPlayer" :playsinline="true"
            :options="playerOptions">
          </video-player>
        </div>
        <div class="movie-body" v-if="info.filmname">
          <el-row>
            <el-col :span="24">
              <el-card :body-style="{ padding: '0px' }">
                <div class="filename-centers">
                  <div class="filename-pic">
                    <el-image v-if="info.imagetype == 2" :src="baseUrls + info.posterfile" class="image"
                      style="width: 99%;height: auto;margin: 0px auto;" />
                    <el-image v-if="info.imagetype == 1" :src="info.posterfile" class="image"
                      style="width: 99%;height: auto;margin: 0px auto;" />
                  </div>
                  <div class="filename-content">
                    <div style="padding: 6px;">
                      <div class="font-text">{{ info.filmname }}</div>
                      <div class="font-text">年份：{{ info.year }}</div>
                      <div class="font-text">导演：{{ info.director }}</div>
                      <div class="font-text" v-if="info.screenwriter"> 编剧：{{
                        info.screenwriter }}
                      </div>
                      <div class="font-text">演员：{{ info.actress }}</div>
                      <div class="font-text" v-if="info.description">类型：{{
                        info.description }}
                      </div>
                      <div class="font-text">地区（国家）：{{ info.filmcountry }}
                      </div>
                    </div>
                  </div>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </div>
        <div class="App-body" style="color: rgb(0, 119, 34);">{{ info.title }}剧情简介：</div>
        <div class="App-body" v-html="info.maincontent"></div>
        <div class="app-image-lazy">
          <el-card shadow="hover" :body-style="{ padding: '8px', cursor: 'pointer', textAlign: 'left', }">
            <div v-if="info.tag[0] != 'Kiss'">添加群主微信(SoSunlive16168)，进微信群大家闲聊和资源共享</div>
            <div v-if="info.tag[0] != 'Kiss'">
              <img src="../../public/imgs/qunzhu_1.jpg" style="width:288px;height: auto;"></img>
            </div>
            <div v-if="info.tag[0] == 'Kiss'">添加群主微信(sosunlive8888)，进微信群大家闲聊和资源共享</div>
            <div v-if="info.tag[0] == 'Kiss'">
              <img src="../../public/imgs/qunzhu_2.jpg"  style="width:288px;height: auto;"></img>
            </div>
          </el-card>
        </div>
        <div style="color: rgb(0, 119, 34);margin:8px auto;">很想很想你的视频和图片 · · · · · ·</div>
        <div class="app-image-title">
          <div class="app-image-title-item" @click="typechange(34)" :class="{ 'title-color': resourcestype == 34 }">
            图片
            <span v-show="resourcestype == 34" :class="{ 'title-line': resourcestype == 34 }"></span>
          </div>
          <div class="app-image-title-item" @click="typechange(35)" :class="{ 'title-color': resourcestype == 35 }">
            视频
            <span v-show="resourcestype == 35" :class="{ 'title-line': resourcestype == 35 }"></span>
          </div>
        </div>
        <div v-if="resourcesList.length == 0&&resourcestype!=36" style="text-align:center">
          暂无！ (T⌓T)
        </div>
 
        <div class="app-image-lazy" v-for="(val, index) in resourcesList" :key="index">
          <div v-if="val.category_id == 34">
            <div class="image-centers" v-if="val.image">
              <el-image :src="val.image" lazy style="width: 24%;margin-left: 1%;margin-top: 6px;" :preview-src-list="[val.image]"></el-image>
            </div>
            <div class="image-centers" v-if="!val.image">
              <el-image v-for="(vals, index) in  val.images" :key="index" :src="vals" lazy style="width: 24%;margin-left: 1%;margin-top: 6px;"
                :preview-src-list="val.images"></el-image>
            </div>
          </div>
          <div v-if="val.category_id == 35">
            <video-player class="video-player vjs-custom-skin" ref="videoPlayer" :playsinline="true"
              :options="val.playerOptions">
            </video-player>
          </div>
        </div>
        <div>
          <el-pagination v-if="resourcestotal > 0" background @current-change="getresourcespage"
            :current-page="resourcespage" :page-size="ResPageSize" layout="total,prev,pager,next" :total="resourcestotal">
          </el-pagination>
        </div>
        <!--
        <div class="app-image-lazy" v-if="resourcespage < resourcestotal">
          <el-button size="mini" @click.stop="getresourcespage()" style="margin: 0px auto;">加载更多</el-button>
        </div>-->
        <div class="nav_centers">
          <div style="color: rgb(0, 119, 34);margin: 8px auto;">很想很想你的评论 · · · · · ·</div>
          <el-button type="warning" @click="showEdit = 1" size="mini">发表评论</el-button>
          <div class="htmledits" v-show="showEdit == 1">
            <vue-editor style="width: 100%;" id="editor" class="editor" useCustomImageHandler
              @image-added="handleImageAdded" v-model="content" :editor-toolbar="customToolbar"> </vue-editor>
            <el-button type="warning" size="mini" plain @click="submits()">提交</el-button>
            <el-button type="danger" size="mini" @click="showEdit = 0" plain>收回</el-button>
          </div>
          <div class="htmledits" v-show="showEdit == 2">
            <el-upload class="upload-demo" name="file" action="https://sosunlive.com/api/common/upload"
              :on-preview="handlePreview" :on-remove="handleRemove" :file-list="fileList" :before-upload="beforeUpload"
              accept=".mp4" :limit="1">
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">只能上传mp4文件，且不超过50mb</div>
            </el-upload>
            <el-button type="warning" plain>提交</el-button>
            <el-button type="danger" @click="showEdit = 0" plain>收回</el-button>
          </div>
        </div>
        <div class="commit-center">
          <div v-if="replyList.length == 0" style="text-align:center"> 暂无！ (T⌓T)</div>
          <div class="commit-items" v-for="(vals, index) in replyList" v-if="replyList.length>0" :key="index">
            <el-row :gutter="20" class="el-row">
              <el-col :span="24">
                <el-avatar :src="baseUrls + vals.user.avatar" shape="square" :size="28" fit="contain"></el-avatar>
              </el-col>
              <el-col :span="24"><span>{{ vals.user.nickname }}</span></el-col>
            </el-row>
            <div class="maincontent" v-html="vals.maincontent"></div>
            <div v-html="vals.createtime"></div>
            <div style="margin:12px auto">
              <el-button type="warning" plain @click="process = vals" size="mini">回复</el-button>
            </div>
            <div v-show="process == vals">
              <vue-editor style="width: 100%;" id="editor" class="editor" useCustomImageHandler
                @image-added="handleImageAdded" v-model="contents" :editor-toolbar="customToolbar"> </vue-editor>
              <div style="margin:12px auto">
                <el-button type="warning" size="mini" plain @click="submitss()">提交</el-button>
                <el-button type="danger" size="mini" @click="process = ''" plain>收回 </el-button>
              </div>
            </div>
            
            <div class="commit-items" v-for="(valss, indexs) in vals.pick.data" :key="indexs">
              <el-row :gutter="20" class="el-row">
                <el-col :span="24">
                  <el-avatar :src="baseUrls + valss.user.avatar" shape="square" :size="28" fit="contain"></el-avatar>
                </el-col>
                <el-col :span="24"><span>{{ valss.user.username }}</span></el-col>
              </el-row>
              
              <el-card class="box-card" style="margin:10px auto" v-if="valss.reply" >
              <el-row :gutter="20" class="el-row" v-if="valss.reply" style="margin-left:12px">
                <el-col :span="24">
                  >>
                </el-col>
                <el-col :span="24">
                  <el-avatar v-if="valss.reply" :src="baseUrls + valss.replyuser.avatar" shape="square" :size="28" fit="contain"></el-avatar>
                </el-col>
                <el-col :span="24"><span>{{ valss.replyuser.username }}</span></el-col>
                <el-col :span="24"><div class="maincontent"  v-html="valss.replymaincontent"></div></el-col>
              </el-row>
              </el-card>

              <div class="maincontent" v-html="valss.maincontent"></div>
              <div v-html="valss.createtime"></div>
              <div style="margin:12px auto">
                <el-button type="warning" plain @click="process = valss" size="mini">回复</el-button>
              </div>
              <div v-show="process == valss">
                <vue-editor id="editor" class="editor" useCustomImageHandler @image-added="handleImageAdded"
                  v-model="contentss" :editor-toolbar="customToolbar"> </vue-editor>
                <div style="margin:12px auto">
                  <el-button type="warning" plain @click="submitsss()" size="mini">提交</el-button>
                  <el-button type="danger" @click="process = ''" plain size="mini">收回</el-button>
                </div>
              </div>
            </div>
            <el-button type="primary" plain @click="getpicklist(vals, index)" size="mini"
              v-show="vals.ppage < vals.pick.last_page" style="margin-top:12px">加载更多</el-button>
          </div>
        </div>
        <el-pagination v-if="total > 0" background @current-change="handleCurrentChange" :current-page="currentPage"
          :page-size="pageSize" layout="total,prev,pager,next" :total="total">
        </el-pagination>
      </div>
      <div class="right">
        <div class="login-centers">
          <div class="login-title" v-show="active == 1">登录</div>
          <el-form v-show="active == 1" ref="loginForm" autocomplete="on" label-position="left">
            <el-form-item prop="account">
              <el-input ref="account" v-model="loginForm.account" placeholder="请输入手机号" name="account" type="text"
                tabindex="1" autocomplete="on" size="large">
              </el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input ref="password" v-model="loginForm.password" placeholder="请输入密码" name="password" type="password"
                tabindex="1" autocomplete="on" size="large">
              </el-input>
            </el-form-item>
            <el-button type="warning" size="small" @click.native.prevent="handleLogin">登录</el-button>
          </el-form>
          <el-form v-show="active == 3" ref="userInfo" autocomplete="on" label-position="center">
            <el-form-item prop="circleUrl" align="center" style="margin-bottom:0px;text-align: center;">
              <img :src="baseUrls + userInfo.avatar" style="width: 40px; height: auto; border-radius: 3px" />
            </el-form-item>
            <el-form-item prop="nickname" style="margin-bottom:0px" align="center" bottom="0px">
              <span>{{ userInfo.username }} | 积分：{{ userInfo.score }}</span>
            </el-form-item>
            <!--
            <el-form-item prop="score" align="center" bottom="0px">
                <span>积分：{{ userInfo.score }}</span>
            </el-form-item>-->
            <el-form-item style="margin-bottom:0px" align="center" bottom="0px">
              <el-button type="warning" @click="logout()" size="mini">注销</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class="user-centers">
          <div class="user-text" v-show="active == 1">请先登陆/注册</div>
          <div class="user-info" v-show="active == 3">
            <div class="item" @click="toSubmitRes()">
              <img src="../../public/imgs/zi1.png" style="width: 38px;height: auto;" />
              <div class="item-name">发布视/图</div>
            </div>
            <!--
            <div class="item" @click="navToRes()">
              <img src="../../public/imgs/zi4.png" style="width: 45px;height: auto;" />
              <div class="item-name">发布资源</div>
            </div>-->
          </div>
        </div>
        <div class="hot_lists">
          <div class="hot_top_tites">很想很想你的热榜 · · · · · ·</div>
          <HotItem :hotList="hotList" :hotTotal="hotTotal" @handleHotCurrentChange="handleHotCurrentChange"></HotItem>
        </div>
        <div class="web_text">
          <div>浙ICP备17001033号-1</div>
          <div>临海市暖心日用百货商行 版权所有</div>
          <div>Copyright © 2022 All rights reserved.</div>
          <div v-if="info.tag[0] != 'Kiss'">添加群主微信(SoSunlive16168)，进微信群大家闲聊和资源共享 </div>
          <div v-if="info.tag[0] == 'Kiss'">添加群主微信(sosunlive8888)，进微信群大家闲聊和资源共享</div>
          <div class="wxinfo" v-if="info.tag[0] == 'Kiss'">
            <img src="../../public/imgs/qunzhu_2.jpg" width="100%" height="auto">
          </div>
          <div class="wxinfo" v-if="info.tag[0] != 'Kiss'">
            <img src="../../public/imgs/qunzhu_1.jpg" width="100%" height="auto">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { VueEditor } from "vue2-editor"
import ApiCon from '../util/Api/index'
import * as imageConversion from 'image-conversion'

import HotItem from '@/components/HotItem';
import axios from "axios"
export default {
  name: 'detail',
  data() {
    return {
      id: this.$route.params.id,
      searchList: [
        "恐怖",
        "生活",
        "Kiss",
        "爱情",
      ],

      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
        autoplay: false, // 如果为true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 是否视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [{
          type: "video/mp4", // 类型
          src: '' // url地址
        }],
        poster: '', // 封面地址
        notSupportedMessage: '', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: true // 是否显示全屏按钮
        }
      },
      resourcestotal: 0,
      resourcespage: 1,
      resourcesList: [],
      value: '',
      ppage: 1,
      process: '',
      loading: false,
      content: '',
      contents: '',
      contentss: '',
      showEdit: 0,
      customToolbar: [
        [{ 'header': [false, 1, 2, 3, 4, 5, 6] }], //标题
        ['bold', 'italic', 'underline'], //加粗\斜体\下划线
        ['italic'], //斜体----也可以拆开来写，用于换位置顺序
        [{ 'align': '' }, { 'align': 'center' }, { 'align': 'right' }], //对齐
        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' }], //有序无序列表
        [{ 'background': [] }, { 'color': [] }], //背景色
        ['image', 'link'], //图片、链接
        ['strike'], //作废（文字中间一横）
        ['underline'],
        ['clean'], //清楚格式（所谓去掉背景色这些花里胡巧）
        ['code'], //代码
        ['code-block'] //代码块
        //['video'] 视频
      ],
      version: 1,
      product: '',
      currentPage: 1,  // 当前页码
      pageSize: 10,  // 每页显示的行数
      hotList:[],
      hotTotal:1,
      ResPageSize: 5,
      active: 1,
      meType: 1,
      show: true,//控制按钮
      fileList: [

      ],
      userInfo: {
        avatar: '/avatar.png',
        username: '您好',
        score: 0
      },
      loginForm: {
        account: '',
        password: ''
      },
      total: 1,
      count: 1,
      searchText:'',
      List:[],
      replyList: [],
      baseUrl: '',
      baseUrls: 'https://www.sosunlive.com',
      resourcestype: 34,
      info: {
        title: '',
        tag: []
      },
    };
  },
  components: {
    VueEditor,
    HotItem
  },
  mounted() {
    this.ApiCon = new ApiCon(this.$store)
    this.ApiConUrl = this.ApiCon.getApiUrl()
  },
  created() {
    this.getProductInfo()
    this.ApiCon = new ApiCon(this.$store)
    this.baseUrl = this.ApiCon.getApiUrl()
    let searchList = window.localStorage.getItem("communityLists");
    if (searchList) {
      this.searchList = JSON.parse(searchList);
    }
    let userInfo = window.localStorage.getItem("userinfo")
    if (userInfo) {
      this.active = 3
      this.userInfo = JSON.parse(userInfo)
    }else{
     // this.$router.push("/Login");
    }
    //document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  },
  methods: {
    handleHotCurrentChange(val){
      this.hotList = [];
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      this.$api.mall
        .getHotList({
          type:this.info.category_ids,
          page: val,
          limit: this.pageSize,
        })
        .then((ress) => {
          let res = ress.data;
          for (var i = 0; i < res.data.data.length; i++) {
            res.data.data[i].shows = true;
            res.data.data[i].tag = res.data.data[i].tag.split(",");
          }
          this.hotTotal = res.data.total;
          this.hotList = res.data.data;
        });
    },
    getHotLists() {
      this.hotList = [];
      this.$api.mall
        .getHotList({
          page: 1,
          type:this.info.category_ids,
          limit: this.pageSize,
        })
        .then((ress) => {
          let res = ress.data;
          for (var i = 0; i < res.data.data.length; i++) {
            res.data.data[i].shows = true;
            res.data.data[i].tag = res.data.data[i].tag.split(",");
          }
          this.hotTotal = res.data.total;
          this.hotList = res.data.data;
        });
    },
    NvoToCos() {
      let self = this;
      self.$router.push("/community");
    },
    subRes(val) {
      //setFilmUns
      //resources_id
      let self = this
      this.$api.mall
        .setFilmUns({
          resources_id: val.id
        })
        .then((res) => {
          console.log(res);
          if (res.code == 1) {
            self.$message.success('解锁成功')
            self.initresources()
          } else {
            self.$message.error("提交失败，请稍后再试");
          }
        });
    },
    hreflinnks(val) {
      if (window.screen.width > 420) {
       let str=val.substring(0, 4);
       if(str=='http'){
         window.open(val)
       }else{
           //this.$message.success('链接复制成功，请用浏览器打开!')
        const text = document.createElement('input'); // 创建节点
        text.setAttribute('readonly', 'readonly');
        text.value = val; // 赋值
        document.body.appendChild(text); // 插入节点
        text.setSelectionRange(0, text.value.length);
        text.select(); // 选中节点
        document.execCommand('copy'); // 执行浏览器复制方法
        if (document.body.removeChild(text)) {
          this.$message({ type: 'success', message: '复制成功' })
        } else {
          this.$message({ type: 'error', message: '复制失败' })
        }
       }
      } else {
        //this.$message.success('链接复制成功，请用浏览器打开!')
        const text = document.createElement('input'); // 创建节点
        text.setAttribute('readonly', 'readonly');
        text.value = val; // 赋值
        document.body.appendChild(text); // 插入节点
        text.setSelectionRange(0, text.value.length);
        text.select(); // 选中节点
        document.execCommand('copy'); // 执行浏览器复制方法
        if (document.body.removeChild(text)) {
          this.$message({ type: 'success', message: '链接复制成功，请用浏览器打开!' })
        } else {
          this.$message({ type: 'error', message: '复制失败' })
        }
      }
    },
    copyText2(value) {
     //this.$message.success('链接复制成功，请用浏览器打开!')
     const text = document.createElement('input'); // 创建节点
        text.setAttribute('readonly', 'readonly');
        text.value = value; // 赋值
        document.body.appendChild(text); // 插入节点
        text.setSelectionRange(0, text.value.length);
        text.select(); // 选中节点
        document.execCommand('copy'); // 执行浏览器复制方法
        if (document.body.removeChild(text)) {
          this.$message({ type: 'success', message: '链接复制成功，请用浏览器打开!' })
        } else {
          this.$message({ type: 'error', message: '复制失败' })
        }
    },
    navToRes() {
      let self = this
      self.$router.push("/SubmitRes/" + self.info.title + "/" + self.info.id + '/4')
      //self.$router.push("/Reply/" + self.info.id)
    },
    logout() {
      let self = this
      window.localStorage.removeItem("token")
      window.localStorage.removeItem("userinfo")
      self.active = 1
    },
    typechange(val) {
      let self = this
      self.resourcestype = val
      //if(val!=6){
        self.initresources()
      //}
    },
    setHot(val) {
      localStorage.setItem("hotText", val)
      history.go(-1)
    },
    beforeUpload(file) {
      //console.log(file,"99999")
      let isRightSize = file.size / (1024 * 1024) < 30
      if (!isRightSize) {
        this.$message.error(`文件大小超过30M`)
        return false
      }
    },
    getUserInfos() {
      let self = this
      this.$api.mall.getUserInfo({
      }).then((ress) => {
        console.log("welcome")
        self.active = 3
        self.userInfo.score  = ress.data.score
        window.localStorage.setItem("userinfo", JSON.stringify(self.userInfo))
      }).catch(err => {
        self.$message.e.error(err.data.msg); // 提示信息
        if (err.data.code == '401') {
          window.localStorage.removeItem("token")
          self.active = 1
        }
      })
    },
    navTo(val) {
      let self = this;
      self.$router.push("/Detail/" + val.id);
      location.reload()
    },
    backgo() {
      let self = this;
      //self.$router.push("/community");
      history.go(-1)
    },
    toSubmitRes() {
      let self = this
      self.$router.push("/SubmitRes/" + self.info.id )
    },
    submits() {
      let self = this
      if (!window.localStorage.getItem("token")) {
        self.$message.error("请先登录或者注册")
        return
      }
      if (!self.content) {
        self.$message.error("请填写内容")
        return
      }
      this.$api.mall.speaAdd({
        maincontent: self.content,
        isreply: 1,
        media_id: self.id,
        pick: '',
        pickuser: '',
        reply: '',
        replyuser: ''
      }).then((res) => {
        console.log(res)
        if (res.code == 1) {
          self.$message.success("提交成功")
          self.content = ''
          self.showEdit = 0
          self.initReply()
        } else {
          self.$message.error("提交失败，请刷新后重新提交")
        }
      });
    },
    submitss() {
      let self = this
      if (!window.localStorage.getItem("token")) {
        self.$message.error("请先登录或者注册")
        return
      }
      if (!self.contents) {
        self.$message.error("请填写内容")
        return
      }
      this.$api.mall.speaAdd({
        maincontent: self.contents,
        isreply: 2,
        media_id: self.id,
        pick: self.process.id,
        //pickuser: self.process.user.id
      }).then((res) => {
        console.log(res)
        if (res.code == 1) {
          self.$message.success("提交成功")
          self.contents = ''
          self.process = ''
          self.initReply()
        } else {
          self.$message.error("提交失败，请刷新后重新提交")
        }
      });
    },
    submitsss() {
      let self = this
      if (!window.localStorage.getItem("token")) {
        self.$message.error("请先登录或者注册")
        return
      }
      if (!self.contentss) {
        self.$message.error("请填写内容")
        return
      }
      this.$api.mall.speaAdd({
        maincontent: self.contentss,
        isreply: 2,
        media_id: self.id,
        pick: self.process.pick,
        //pickuser: self.process.pickuser.id,
        replyuser: self.process.user.id,
        reply: self.process.id,
      }).then((res) => {
        console.log(res)
        if (res.code == 1) {
          self.$message.success("提交成功")
          self.contentss = ''
          self.process = ''
          self.initReply()
        } else {
          self.$message.error("提交失败，请刷新后重新提交")
        }
      });
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    async handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      let self = this;
      if (!window.localStorage.getItem("token")) {
       self.$message.error('请先登录')
        return
     }
      // An example of using FormData
      // NOTE: Your key could be different such as:
      // formData.append('file', file)
      let types = ["image/jpeg", "image/jpg", "image/png"];
      const isImage = types.includes(file.type);
      // const isLtSize = file.size / 1024 / 1024 < 2;
      if (!isImage) {
          this.$message.error("上传图片只能是 JPG、JPEG、PNG 格式!");
          return false;
      }
      //图片压缩插件
    return new Promise((resolve) => {
        // 压缩到100KB,这里的100就是要压缩的大小,可自定义
        imageConversion.compressAccurately(file, 300).then(res => {
            console.log(res);
            let files = new File([res], file.name, { type: file.type });
            var formData = new FormData();
            formData.append("file", files);
            axios({
              url: "https://sosunlive.com/api/common/upload",
              method: "POST",
              headers: {
                'Token':window.localStorage.getItem("token")
              },
              data: formData,
            })
              .then((result) => {
                let url = 'https://www.sosunlive.com'+result.data.data.url;
                Editor.insertEmbed(cursorLocation, "image", url);
                resetUploader();
              })
              .catch((err) => {
                console.log(err);
              });

          });
      })
    },
    updateData(e) {
      this.content = e;
    },
    handleCurrentChange(val) {
      this.ApiCon = new ApiCon(this.$store)
      this.ApiConUrl = this.ApiCon.getApiUrl()
      //console.log(this.ApiCon.gets('pages'))
      //this.$router.push("/cart");
      document.documentElement.scrollTop = 0
      this.currentPage = val
      this.list = []
      this.$api.mall.getSpeakList({
        page: this.currentPage,
        limit: this.pageSize,
        ids: this.id
      }).then((res) => {
        this.total = res.data.data.total
        this.replyList = res.data.data.data
      });
    },
    getpicklist(val, index) {
      let self = this
      let ppage = val.ppage
      ppage = parseInt(ppage) + 1
      this.$api.mall.getPickList({
        pick: val.pick.data[0].pick,
        page: ppage,
        limit: 14,
      }).then((ress) => {
        let res = ress.data
        console.log(res)
        self.replyList[index].ppage = ppage
        for (var i = 0; i < res.data.data.length; i++) {
          self.replyList[index].pick.push(res.data.data[i])
        }
      });
    },
    //登录
    handleLogin() {
      let self = this
      if (!self.loginForm.account) {
        self.$message.error("请输入手机号码")
        return
      }
      if (!self.loginForm.password) {
        self.$message.error("请输入密码")
        return
      }
      self.$api.mall.login(self.loginForm).then((res) => {
        console.log(res)
        if (res.code == 1) {
          self.active = 3
          self.userInfo = res.data.userinfo
          window.localStorage.setItem("userinfo", JSON.stringify(res.data.userinfo))
          window.localStorage.setItem("token", res.data.userinfo.token)
          self.$message.success("登陆成功")
        } else {
          self.$message.error("登陆失败")
        }
      });
    },
    initresources() {
      this.resourcesList = []
      //let val = this.info.tag[0]
      this.resourcestotal = 0
      this.resourcespage = 1
      this.$api.mall.getresources({
        page: 1,
        limit: 5,
        type: this.resourcestype,
        title: this.info.title,
        media_id: this.id,
      }).then((res) => {
        for (var i = 0; i < res.data.data.data.length; i++) {
          if (res.data.data.data[i].category_id == '34') {
            if (res.data.data.data[i].image) {
              res.data.data.data[i].image = this.baseUrls + res.data.data.data[i].image
            } else {
              res.data.data.data[i].images = res.data.data.data[i].images.split(",")
              for (var a = 0; a < res.data.data.data[i].images.length; a++) {
                res.data.data.data[i].images[a] = this.baseUrls + res.data.data.data[i].images[a]
              }
            }
          } else if (res.data.data.data[i].category_id == '35') {
            let file=res.data.data.data[i].attachfile
            if(file==''){
              file = res.data.data.data[i].file
            }
            if (file) {
              //attachfile playerOptions
              res.data.data.data[i]['playerOptions'] =
              {
                playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
                autoplay: false, // 如果为true,浏览器准备好时开始回放。
                muted: false, // 默认情况下将会消除任何音频。
                loop: false, // 是否视频一结束就重新开始。
                preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                language: 'zh-CN',
                aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                sources: [{
                  type: "video/mp4", // 类型
                  src: 'https://www.sosunlive.com' + file // url地址
                }],
                poster: '', // 封面地址
                notSupportedMessage: '', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
                controlBar: {
                  timeDivider: true, // 当前时间和持续时间的分隔符
                  durationDisplay: true, // 显示持续时间
                  remainingTimeDisplay: false, // 是否显示剩余时间功能
                  fullscreenToggle: true // 是否显示全屏按钮
                }
              }
            }
          }
        }
        this.resourcesList = res.data.data.data
        console.log(  this.resourcesList )
        // this.resourcestotal = res.data.data.last_page
        this.resourcestotal = res.data.data.total
      });
    },
    getresourcespage() {
      /**
      if (parseInt(this.resourcespage + 1) > parseInt(this.resourcestotal)) {
        this.$message.error("到底了")
        return
      }
      this.resourcespage = this.resourcespage + 1**/
      let val = this.info.tag[0]
      this.$api.mall.getresources({
        page: this.resourcespage,
        limit: 5,
        type: this.resourcestype,
        title: this.info.title,
        media_id: this.id,
      }).then((res) => {
        for (var i = 0; i < res.data.data.data.length; i++) {
          if (res.data.data.data[i].type == 34) {
            if (res.data.data.data[i].image) {
              res.data.data.data[i].image = this.baseUrls + res.data.data.data[i].image
            } else {
              res.data.data.data[i].images = res.data.data.data[i].images.split(",")
              for (var a = 0; a < res.data.data.data[i].images.length; a++) {
                res.data.data.data[i].images[a] = this.baseUrls + res.data.data.data[i].images[a]
              }
            }
          } else if (res.data.data.data[i].type == 35) {
            let file=res.data.data.data[i].attachfile
            if(file){
              file = res.data.data.data[i].file
            }
            if (file) {
              //attachfile playerOptions
              res.data.data.data[i]['playerOptions'] =
              {
                playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
                autoplay: false, // 如果为true,浏览器准备好时开始回放。
                muted: false, // 默认情况下将会消除任何音频。
                loop: false, // 是否视频一结束就重新开始。
                preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                language: 'zh-CN',
                aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                sources: [{
                  type: "video/mp4", // 类型
                  src: 'https://www.sosunlive.com' + file // url地址
                }],
                poster: '', // 封面地址
                notSupportedMessage: '', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
                controlBar: {
                  timeDivider: true, // 当前时间和持续时间的分隔符
                  durationDisplay: true, // 显示持续时间
                  remainingTimeDisplay: false, // 是否显示剩余时间功能
                  fullscreenToggle: true // 是否显示全屏按钮
                }
              }
            }
          }
          this.resourcesList.push(res.data.data.data[i])
        }
      });
    },
    initReply() {
      this.replyList = []
      this.$api.mall.getSpeakList({
        page: 1,
        limit: 14,
        ids: this.id
      }).then((ress) => {
        let res = ress.data
        for (var i = 0; i < res.data.data.length; i++) {
          res.data.data[i].ppage = 1
        }
        this.replyList = res.data.data
        this.total = res.data.total
      });
    },
    init() {
      this.currentPage = 1;

      this.List = [];
      if (!this.searchText) {
        return
      }
      this.$api.mall
        .getList({
          page: this.currentPage,
          limit: 12,
          title: this.searchText,
        })
        .then((ress) => {
          let res = ress.data;
          this.List = res.data.data
        })
    },
    getProductInfo() {
      let id = this.id;
      let self = this
      this.$api.mall.getDetail({ id: id }).then((res) => {
        res.data.data.tag = res.data.data.tag.split(",")
        self.info = res.data.data
        self.playerOptions.sources[0].src = self.baseUrls + self.info.attachfile
        self.initresources()
        self.initReply()
        self.getHotLists()

        if (window.localStorage.getItem("token")) {
          // this.getUserRes()
          // this.getUserInfos()
          //this.active = 3
        }
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
      });
    }
  }
};
</script>
<style lang="scss">
@media (max-width: 8000px) and (min-width: 1024px) {
  ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #fff;
    }
    ::-webkit-scrollbar-thumb {
        background-color: #e67843;
  }
  .detail-form {
    width: 100%;
    overflow: hidden;
    margin: 0px auto;
    padding: 0px;
    .maincontent{
        box-sizing: border-box;
        width: 288px;
        overflow: hidden;
        margin-top: 10px;
        padding: 0px;
      }
    .nav-products {
      width: 100%;
      overflow: hidden;
      position: fixed;
      top: 45px;
      left: 0px;
      z-index: 10;

      .products-list {
        width: 980px;
        overflow-y: scroll;
        max-height: 688px;
        margin: 0px auto;
        padding: 0px;
        box-sizing: border-box;
        background: #fff;

        .products-item {
          width: 100%;
          overflow: hidden;
          margin: 0px auto;
          cursor: pointer;
          padding: 10px;
          border-bottom: 1px dashed #f2f2f2;
          box-sizing: border-box;

          .products-title {
            width: 100%;
            overflow: hidden;
            margin: 0px auto;
            padding: 10px;
            box-sizing: border-box;
          }

          .products-image {
            width: 68px;
            overflow: hidden;
            box-sizing: border-box;
            padding: 10px;
            height: 68px;

          }
        }
      }
    }

    .title-cneters {
      width: 100%;
      padding: 0px;
      box-sizing: border-box;
      overflow: hidden;
      margin: 58px auto auto auto;

      .title-top-content {
        width: 980px;
        box-sizing: border-box;
        padding: 0px;
        overflow: hidden;
        margin: 0px auto;
      }

      .tag-centers {
        width: 100%;
        overflow: hidden;
        margin: 0px auto;
        padding: 0px;

        .tag-items:nth-child(1) {
          margin-left: 0px;
        }

        .tag-items-title {
          position: relative;
          display: inline-block;
          height: 30px;
          line-height: 30px;
          color: maroon;
          vertical-align: top;
          border-radius: 100px;
        }

        .tag-items {
          position: relative;
          display: inline-block;
          height: 30px;
          padding: 0 12px;
          font-size: 13px;
          margin-left: 6px;
          line-height: 30px;
          color: maroon;
          vertical-align: top;
          border-radius: 100px;
          background: rgb(246 118 23 / 10%);
          cursor: pointer;
        }
      }

      .titles {
        color: #121212;
        font-size: 18px;
        line-height: 1.22;
        margin: 12px 0;
        word-wrap: break-word;
        font-weight: 600;
        font-synthesis: style;
      }
    }

    .nav_centers {
      width: 100%;
      padding: 10px 0px;
      overflow: hidden;
      box-sizing: border-box;
      margin: 0px auto;
      line-height: 28px;

      .htmledits {
        width: 100%;
        overflow: hidden;
        margin: 10px auto;

        .upload-demo {
          width: 100%;
          background: #fff;
          overflow: hidden;
          margin: 10px auto;
        }
      }
    }

    .App-main {
      width: 980px;
      overflow: hidden;
      margin: 0px auto;
      padding: 10px;

      .right {
        width: 290px;
        overflow: hidden;
        background: #fff;
        border-radius: 2px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        float: right;

        .user-centers {
          width: 100%;
          overflow: hidden;
          box-sizing: border-box;
          margin: 10px auto;
          padding: 0px;
          border-radius: 2px;
          -webkit-box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
          box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
          background: #fff;

          .user-info {
            width: 100%;
            overflow: hidden;
            box-sizing: border-box;
            margin: 0px auto;
            padding: 0px;

            .item {
              width: 128px;
              overflow: hidden;
              float: left;
              margin: 0px auto;
              box-sizing: border-box;
              padding: 10px;
              text-align: center;
              cursor: pointer;
            }
          }

          .user-text {
            color: maroon;
            text-align: center;
            font-size: 14px;
            line-height: 1.22;
            margin: 12px 0;
            word-wrap: break-word;
            font-weight: 500;
            font-synthesis: style;
          }
        }

        .hot_lists {
          width: 100%;
          margin: 10px auto;
          border-radius: 2px;
          -webkit-box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
          box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
          background: #fff;
          font-size: $fontJ;
          box-sizing: border-box;
          padding: 10px;
          overflow: hidden;

          .hot_top_tites {
            width: 100%;
            overflow: hidden;
            margin: 0px auto;
            color: rgb(217, 83, 80);
            font-size: 14px;
          }
        }

        .web_text {
          width: 100%;
          overflow: hidden;
          margin: 10px auto;
          box-sizing: border-box;
          padding: 10px 15px 10px;
          min-width: 0px;
          color: rgb(132, 145, 165);
          font-size: 14px;
          line-height: 2;
          border-radius: 2px;
          background: #fff;

          .wxinfo {
            width: 90%;
            overflow: hidden;
            margin: 0px auto;
          }

          .hot_items {
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .hot_items:hover {
            color: #409EFF;
            text-decoration: underline;
            cursor: pointer;
          }
        }

        .login-centers {
          width: 100%;
          font-size: $fontJ;
          box-sizing: border-box;
          padding: 10px;
          overflow: hidden;
          margin: 0px auto;

          .login-title {
            width: 100%;
            overflow: hidden;
            text-align: left;
            line-height: 28px;
          }
        }
      }

      .left {
        width: 680px;
        float: left;
        margin: 0px auto;
        overflow: hidden;

        .commit-center {
          width: 100%;
          overflow: hidden;
          margin: 10px auto;

          .commit-items {
            width: 100%;
            overflow: hidden;
            margin: 12px auto;
            background: #fff;
            border-radius: 2px;
            -webkit-box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
            box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            padding: 16px 15px 10px;

            p {
              padding: 0px;
              margin: 0px;
            }
          }
        }

        .el-row {
          margin-bottom: 0px;
        }

        .media-center {
          width: 100%;
          overflow: hidden;
          margin: 12px auto;
          background: #fff;
          border-radius: 2px;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          padding: 16px 15px 10px;
        }

        .app-image-title {
          width: 100%;
          box-sizing: border-box;
          overflow: hidden;
          margin: 10px auto;
          padding: 0px;
          /**
          .app-image-title-item:nth-child(1) {
            border-right: 1px solid #409EFF;
          }**/

          .app-image-title-item {
            width: 60px;
            line-height: 35px;
            overflow: hidden;
            font-size: 14px;
            float: left;
            position: relative;
            text-align: center;
            cursor: pointer;

            .title-line {
              width: 60%;
              height: 1.5px;
              background: #409EFF;
              position: absolute;
              bottom: 0px;
              left: 20%;
              display: inline-block;
            }
          }

          .title-color {
            color: #409EFF !important;
          }
        }

        .app-image-lazy {
          width: 100%;
          overflow: hidden;
          margin: 10px auto;
          background: #fff;
          border-radius: 2px;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          padding: 16px 15px 10px;
          text-align: center;

          .hover-link:hover {
            border-bottom: 1px solid #409EFF;
            color: #409EFF;
          }

          .hover-link {
            width: 100%;
            overflow: hidden;
            margin: 0px auto;
            padding: 2px;
            cursor: pointer;
            box-sizing: border-box;
          }
        }

        .movie-body {
          width: 100%;
          overflow: hidden;
          margin: 0px auto;
          background: #fff;
          border-radius: 2px;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          padding: 16px 15px 10px;

          .filename-centers {
            width: 100%;
            overflow: hidden;
            margin: 0px auto;
            box-sizing: border-box;
            padding: 10px;

            .filename-pic {
              width: 128px;
              overflow: hidden;
              float: left;
              margin: 0px auto;
            }

            .filename-content {
              width: calc(100% - 138px);
              overflow: hidden;
              margin: 0px auto;
              float: right;
              padding: 0px;
            }

            .font-text {
              width: 100%;
              line-height: 25px;
              font-size: 13px;
              overflow: hidden;
            }
          }

        }

        .App-body {
          width: 100%;
          overflow: hidden;
          margin: 0px auto;
          background: #fff;
          line-height: 20px;
          border-radius: 2px;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          padding: 10px;

          p {
            padding: 0px;
            margin: 0px;
          }

          div {
            background: none !important;
          }
        }

        .title {
          color: #121212;
          font-size: 24px;
          line-height: 1.22;
          margin: 12px 0;
          word-wrap: break-word;
          font-weight: 600;
          font-synthesis: style;
        }

      }

    }

    .nav-topbar {
      width: 100%;
      overflow: hidden;
      background: #ffe url('/imgs/fade.png') top center repeat-x;
      position: fixed;
      top: 0px;
      left: 0px;
      z-index: 10;

      .mobiles-nav-list {
        display: none;
      }

      .nav-list {
        width: 980px;
        overflow: hidden;
        margin: 0px auto;
        color: maroon;

        .dict-top {
          width: 980px;
          overflow: hidden;
          margin: 0px auto;
          padding: 0px;
          box-sizing: border-box;

          .hot_centers {
            /**
            width: 100%;
            line-height: 45px;
            overflow: hidden;
            margin: 0px auto;
            padding: 0px;
            **/
            width: 100%;
            white-space: nowrap;
            overflow-y: auto;
            box-sizing: border-box;
            padding: 0;
            margin: 0 auto;
            .hot_items {
             // padding-left: 12px;
             /**
              padding-right: 20px;
              float: left;
              text-align: center;
              display: block;
              cursor: pointer;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;**/
              padding-right: 20px;
              cursor: pointer;
              display: block;
              line-height: 40px;
              overflow: hidden;
              text-align: center;
              display: inline-block;
            }

            .hot_items span:hover {
              color: red !important;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) and (min-width: 320px) {
  .detail-form {
    width: 100%;
    overflow: hidden;
    margin: 0px auto;
    padding: 0px;
    .maincontent{
        box-sizing: border-box;
        width: 100%;
        overflow: hidden;
        margin-top: 10px;
        padding: 0px;
      }

    .top_banner {
      width: 100%;
      overflow: hidden;
      margin: 0px auto;
    }

    .nav-products {
      width: 100%;
      overflow: hidden;
      position: fixed;
      top: 45px;
      left: 0px;
      z-index: 10;

      .products-list {
        width: 99.9%;
        overflow: hidden;
        overflow-y: scroll;
        max-height: 688px;
        margin: 0px auto;
        padding: 0px;
        box-sizing: border-box;
        background: #fff;

        .products-item {
          width: 100%;
          overflow: hidden;
          margin: 0px auto;
          cursor: pointer;
          padding: 10px;
          border-bottom: 1px dashed #f2f2f2;
          box-sizing: border-box;

          .products-title {
            width: 100%;
            overflow: hidden;
            margin: 0px auto;
            padding: 10px;
            box-sizing: border-box;
          }

          .products-image {
            width: 68px;
            overflow: hidden;
            box-sizing: border-box;
            padding: 10px;
            height: 68px;

          }
        }
      }
    }
    .nav-topbar {
      width: 100%;
      overflow: hidden;
      position: fixed;
      top: 0px;
      left: 0px;
      z-index: 10;
      background: url('/imgs/fade.png') top center repeat-x;

      .mobiles-nav-list {
        width: 100%;
        padding: 6px;
        overflow: hidden;
        box-sizing: border-box;
        margin: 0px auto;

        .nav-items {
          display: block;
          padding: 0px 6px;
          overflow: hidden;
          box-sizing: border-box;
          color: maroon;
          line-height: 38px;
          text-align: center;
          float: left;
        }

        .nav-backs {
          text-decoration: none;
          box-sizing: border-box;
          display: block;
          padding: 0px 8px 0px 4px;
          overflow: hidden;
          color: maroon;
          line-height: 38px;
          text-align: center;
          float: left;
        }
      }

      .nav-list {
        display: none;
      }
    }

    .nav_centers {
      width: 100%;
      box-sizing: border-box;
      padding: 10px;
      overflow: hidden;
      margin: 0px auto;
      line-height: 28px;

      .htmledits {
        width: 100%;
        overflow: hidden;
        margin: 10px auto;

        .upload-demo {
          width: 100%;
          background: #fff;
          overflow: hidden;
          margin: 10px auto;
        }
      }
    }

    .el-row {
      margin-bottom: 0px;
    }

  
    .title-cneters {
      width: 100%;
      padding-left: 10px;
      padding-right: 10px;
      overflow: hidden;
      box-sizing: border-box;
      margin: 58px auto auto auto;

      .title-top-content {
        margin: 0px auto;
        padding: 0px;
        box-sizing: border-box;
        width: 100%;
        overflow: hidden;
      }

      .tag-centers {
        width: 100%;
        overflow: hidden;
        margin: 0px auto;
        padding: 0px;

        .tag-items-title {
          position: relative;
          display: inline-block;
          height: 32px;
          line-height: 32px;
          color: maroon;
          vertical-align: top;
          border-radius: 100px;
        }

        .tag-items:nth-child(1) {
          margin-left: 0px;
        }

        .tag-items {
          position: relative;
          display: inline-block;
          height: 30px;
          padding: 0 12px;
          font-size: 13px;
          margin-left: 6px;
          line-height: 30px;
          color: maroon;
          vertical-align: top;
          border-radius: 100px;
          background: rgb(246 118 23 / 10%);
          cursor: pointer;
        }
      }

      .titles {
        color: #121212;
        font-size: 24px;
        line-height: 1.22;
        margin: 0px auto;
        word-wrap: break-word;
        font-weight: 600;
        font-synthesis: style;
      }
    }

    .App-main {
      margin: 0px auto;
      padding: 10px;
      box-sizing: border-box;
      width: 100%;
      overflow: hidden;

      .right {
        width: 100%;
        overflow: hidden;
        margin: 10px auto auto auto;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;

        .user-centers {
          width: 100%;
          overflow: hidden;
          box-sizing: border-box;
          margin: 10px auto;
          padding: 0px;
          border-radius: 2px;
          -webkit-box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
          box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
          background: #fff;

          .user-info {
            width: 100%;
            overflow: hidden;
            box-sizing: border-box;
            margin: 0px auto;
            padding: 0px;

            .item {
              width: 128px;
              overflow: hidden;
              float: left;
              margin: 0px auto;
              box-sizing: border-box;
              padding: 10px;
              text-align: center;
            }
          }

          .user-text {
            color: maroon;
            text-align: center;
            font-size: 14px;
            line-height: 1.22;
            margin: 12px 0;
            word-wrap: break-word;
            font-weight: 500;
            font-synthesis: style;
          }
        }
        .hot_lists {
          width: 100%;
          margin: 10px auto;
          border-radius: 2px;
          -webkit-box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
          box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
          background: #fff;
          font-size: $fontJ;
          box-sizing: border-box;
          padding: 10px;
          overflow: hidden;

          .hot_top_tites {
            width: 100%;
            overflow: hidden;
            margin: 0px auto;
            color: rgb(217, 83, 80);
            font-size: 14px;
          }
        }

        .web_text {
          width: 100%;
          overflow: hidden;
          margin: 10px auto;
          box-sizing: border-box;
          padding: 10px 15px 10px;
          min-width: 0px;
          color: rgb(132, 145, 165);
          font-size: 14px;
          line-height: 2;
          border-radius: 2px;
          background: #fff;

          .wxinfo {
            width: 90%;
            overflow: hidden;
            margin: 0px auto;
          }

          .hot_items {
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }

        .login-centers {
          width: 100%;
          font-size: $fontJ;
          box-sizing: border-box;
          padding: 10px;
          overflow: hidden;
          margin: 0px auto;

          .login-title {
            width: 100%;
            overflow: hidden;
            text-align: left;
            line-height: 28px;
          }
        }
      }


      .left {
        width: 100%;
        overflow: hidden;
        margin: 0px auto;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;

        .el-row {
          margin-bottom: 0px;
        }

        .title {
          color: #121212;
          font-size: 24px;
          line-height: 1.22;
          margin: 24px 0;
          word-wrap: break-word;
          font-weight: 600;
          font-synthesis: style;
        }

        .media-center {
          width: 100%;
          overflow: hidden;
          margin: 12px auto;
          background: #fff;
          border-radius: 2px;
          -webkit-box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
          box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          padding: 16px 15px 10px;
        }

        .app-image-title {
          width: 100%;
          overflow: hidden;
          box-sizing: border-box;
          margin: 10px auto;
          padding: 0px;

          /**
          .app-image-title-item:nth-child(1) {
            border-right: 1px solid #409EFF;
          }**/

          .app-image-title-item {
            width: 60px;
            font-size: 14px;
            overflow: hidden;
            line-height: 35px;
            float: left;
            text-align: center;
            position: relative;

            .title-line {
              width: 60%;
              height: 1.5px;
              background: #409EFF;
              position: absolute;
              bottom: 0px;
              left: 20%;
              display: inline-block;
            }
          }

          .title-color {
            color: #409EFF !important;
          }
        }

        .app-image-lazy {
          width: 100%;
          overflow: hidden;
          margin: 10px auto;
          background: #fff;
          border-radius: 2px;
          -webkit-box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
          box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          text-align: center;
          padding: 16px 15px 10px;

          .hover-link:hover {
            border-bottom: 1px solid #409EFF;
            color: #409EFF;
          }

          .hover-link {
            width: 100%;
            overflow: hidden;
            margin: 0px auto;
            padding: 2px;
            cursor: pointer;
            box-sizing: border-box;
          }
        }

        .movie-body {
          width: 100%;
          overflow: hidden;
          margin: 0px auto;
          background: #fff;
          border-radius: 2px;
          -webkit-box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
          box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          padding: 16px 15px 10px;

          .filename-centers {
            width: 100%;
            overflow: hidden;
            margin: 0px auto;
            box-sizing: border-box;
            padding: 10px;

            .filename-pic {
              width: 128px;
              overflow: hidden;
              float: left;
              margin: 0px auto;
            }

            .filename-content {
              width: calc(100% - 130px);
              overflow: hidden;
              margin: 0px auto;
              float: right;
              padding: 0px;
            }

            .font-text {
              width: 100%;
              line-height: 18px;
              font-size: 13px;
              overflow: hidden;
            }
          }
        }

        .App-body {
          width: 100%;
          overflow: hidden;
          margin: 0px auto;
          background: #fff;
          border-radius: 2px;
          line-height: 20px;
          -webkit-box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
          box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          padding: 10px;

          p {
            padding: 0px;
            margin: 0px;
          }

          div {
            background: none !important;
          }
        }

        .commit-center {
          width: 100%;
          overflow: hidden;
          margin: 10px auto;

          .commit-items {
            width: 100%;
            overflow: hidden;
            margin: 12px auto;
            background: #fff;
            border-radius: 2px;
            -webkit-box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
            box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            padding: 16px 15px 10px;

            p {
              padding: 0px;
              margin: 0px;
            }
          }
        }
      }
    }
  }
}
</style>